<div
  class="page-loader"
  [ngClass]="{ show: loaderFlag, 'd-none': !loaderFlag }"
>
  <div class="message-wrap">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
    </div>
  </div>
</div>
