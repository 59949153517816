import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constants } from 'src/app/utils/constants';
import { environment } from '../../../environments/environment';
import { UserRole } from './user-role';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public UserID: string = 'UserID';
  public AgreedTerms: string = 'AgreedTerms';
  public hasAgreedTernsTAG: string = 'hasAgreedTerns';
  public Role: string =
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  public getToken(): string {
    const user = localStorage.getItem(Constants.TOKEN_STORE);
    if (user) {
      let tokenPayload = JSON.parse(user);
      return tokenPayload.token;
    }
    return '';
  }

  public clearUserData(dontClearToken?: boolean) {
    localStorage.removeItem(Constants.TOKEN_STORE);
    localStorage.removeItem(Constants.USER_SETTING);
    localStorage.removeItem(Constants.DISPLAY_ORDER);
    localStorage.clear();
  }

  public get hasLoggedIn() {
    return localStorage.getItem(Constants.TOKEN_STORE) != undefined;
  }

  public set hasLoggedIn(payload: any) {
    localStorage.setItem(Constants.TOKEN_STORE, JSON.stringify(payload));
  }

  public get tenantAccessToken() {
    return localStorage.getItem(Constants.TENANT_TOKEN_STORE);
  }

  public set tenantAccessToken(payload: any) {
    localStorage.setItem(Constants.TENANT_TOKEN_STORE, payload);
  }

  setUserLoginMetrics() {
    try {
      let date: Date = new Date();
      let payload = {
        LoggedInUTCDateTime: date.toISOString(),
        Date: formatDate(date, 'MM/dd/YYYY', 'en'),
      };
      localStorage.setItem(Constants.USER_METRICS, JSON.stringify(payload));
    } catch (error) {}
  }

  getUserLoginMetrics() {
    try {
      const user = localStorage.getItem(Constants.USER_METRICS);
      if (user) {
        let metrixPayload = JSON.parse(user);
        if (metrixPayload) {
          let date: Date = new Date();
          metrixPayload.LoggedOutUTCDateTime = date.toISOString();
          return metrixPayload;
        }
      }
    } catch (error) {}

    return null;
  }

  public get hasAgreedTerns() {
    const user = localStorage.getItem(Constants.TOKEN_STORE);
    if (user) {
      let tokenPayload = JSON.parse(user);
      return (
        tokenPayload.hasAgreedTerns &&
        (tokenPayload.hasAgreedTerns == true ||
          tokenPayload.hasAgreedTerns == 'true' ||
          tokenPayload.hasAgreedTerns == 'True')
      );
    }
    return false;
  }

  public isAdmin() {
    const user = localStorage.getItem(Constants.TOKEN_STORE);
    if (user) {
      let tokenPayload = JSON.parse(user);
      if (tokenPayload) {
        let userT = this.jwtHelper.decodeToken(tokenPayload.token);
        if (userT.hasOwnProperty(this.Role)) {
          return userT[this.Role] == UserRole.ADMIN;
        }
      }
    }
    return false;
  }

  public getUserId() {
    const user = localStorage.getItem(Constants.TOKEN_STORE);
    if (user) {
      let tokenPayload = JSON.parse(user);
      if (tokenPayload) {
        let userT = this.jwtHelper.decodeToken(tokenPayload.token);
        if (userT.hasOwnProperty(this.UserID)) {
          return userT[this.UserID];
        }
      }
    }
    return null;
  }

  public set hasAgreedTerns(payload: any) {
    if (payload) {
      let userT = this.jwtHelper.decodeToken(payload.token);
      if (userT.hasOwnProperty(this.AgreedTerms)) {
        payload.hasAgreedTerns = userT[this.AgreedTerms];
        localStorage.setItem(Constants.TOKEN_STORE, JSON.stringify(payload));
      }
    }
  }

  public hasPropertyAgreedTerms(): boolean {
    const user: any = localStorage.getItem(Constants.TOKEN_STORE);
    if (user) {
      let tokenPayload: any = JSON.parse(user);
      if (tokenPayload) {
        let userT = this.jwtHelper.decodeToken(tokenPayload.token);
        return (
          userT.hasOwnProperty(this.AgreedTerms) &&
          tokenPayload.hasOwnProperty(this.hasAgreedTernsTAG)
        );
      }
    }
    return false;
  }

  public setHasAgreedTerns(hasAgreed: boolean) {
    const user: any = localStorage.getItem(Constants.TOKEN_STORE);
    if (user) {
      let tokenPayload: any = JSON.parse(user);
      if (tokenPayload) {
        tokenPayload.hasAgreedTerns = hasAgreed;
        localStorage.setItem(
          Constants.TOKEN_STORE,
          JSON.stringify(tokenPayload)
        );
      }
    }
  }

  public setDisplayOrder(number: any) {
    localStorage.setItem(Constants.DISPLAY_ORDER, String(number));
  }

  public getIssuers(): any {
    const user = localStorage.getItem(Constants.ISSUERS);
    if (user) {
      let tokenPayload = JSON.parse(user);
      return tokenPayload; // will add interceptor to check the validity later.
    }
    return null;
  }

  public getOrgId(): number {
    const user = localStorage.getItem(Constants.ISSUERS);
    if (user) {
      let tokenPayload = JSON.parse(user);
      if (tokenPayload && tokenPayload.orgId) {
        return tokenPayload.orgId;
      }
    }
    return 0;
  }

  setOrgStore(org: any) {
    let issuer = this.getIssuers();
    if (issuer) {
      issuer.orgId = org.orgId;
      issuer.organizationName = org.name;
      this.setIssuers(issuer);
    }
  }

  public setIssuers(issuerDetails: any) {
    localStorage.setItem(Constants.ISSUERS, JSON.stringify(issuerDetails));
  }

  setTempAccessToken(token: any) {
    if (token == null) {
      localStorage.removeItem(Constants.TEMP_ACCESS_TOKEN);
      return;
    }
    localStorage.setItem(Constants.TEMP_ACCESS_TOKEN, String(token));
  }

  getTempAccessToken() {
    const user: any = localStorage.getItem(Constants.TEMP_ACCESS_TOKEN);
    return user ?? null;
  }

  public getDisplayOrder() {
    const setting = localStorage.getItem(Constants.DISPLAY_ORDER);
    if (setting) {
      return setting;
    }
    return 1;
  }

  getEmailFromTempToken() {
    let token = this.getTempAccessToken();
    if (token) {
      let userT = this.jwtHelper.decodeToken(token);
      if (userT) {
        if (userT.hasOwnProperty('email')) {
          return userT['email'];
        }
        if (userT.hasOwnProperty('preferred_username')) {
          return userT['preferred_username'];
        }
      }
    }
    return null;
  }

  public setSettingsLocally(updatedPayload: any) {
    let payload = {
      stateFilterData: {
        selectedDataSet: updatedPayload.stateFilterData.selectedDataSet,
        selectedPopAdj: updatedPayload.stateFilterData.selectedPopAdj,
        selectedWghtedScores:
          updatedPayload.stateFilterData.selectedWghtedScores,
      },
      countyFilterData: {
        selectedDataSet: updatedPayload.countyFilterData.selectedDataSet,
        selectedWghtedScores:
          updatedPayload.countyFilterData.selectedWghtedScores,
      },
    };
    localStorage.setItem(Constants.USER_SETTING, JSON.stringify(payload));
  }

  public getUserSetting() {
    const setting = localStorage.getItem(Constants.USER_SETTING);
    if (setting) {
      try {
        return JSON.parse(setting);
      } catch (error) {}
    }
    return null;
  }

  public showLoginModal: boolean = false;
  constructor(private http: HttpClient, public jwtHelper: JwtHelperService) {}

  public checkTokenExpired() {
    const user = localStorage.getItem(Constants.TOKEN_STORE);
    if (user) {
      try {
        return this.jwtHelper.isTokenExpired(JSON.parse(user)?.token);
      } catch (error) {}
    }
    return true;
  }

  public isLoadingNarrative(oppId: any, stateCode?: string) {
    const statusQueryUrl = localStorage.getItem(
      Constants.NARRATIVE_DOWNLOAD +
        String(Number(oppId)) +
        String('-' + stateCode)
    );
    if (statusQueryUrl) {
      return statusQueryUrl;
    }
    return undefined;
  }

  public setNarrative(oppId: any, narrativeUrl: string, stateCode?: string) {
    localStorage.setItem(
      Constants.NARRATIVE_DOWNLOAD +
        String(Number(oppId)) +
        String('-' + stateCode),
      String(narrativeUrl)
    );
  }

  public removeNarrative(oppId: any, stateCode?: string) {
    localStorage.removeItem(
      Constants.NARRATIVE_DOWNLOAD +
        String(Number(oppId)) +
        String('-' + stateCode)
    );
  }

  public updateGrantsNarrativeDownloadList(
    isDeletion: boolean,
    grantOppId: any,
    statusQueryString?: string,
    stateCode?: string,
    stateName?: string,
    oppNumber?: string
  ) {
    const nars = localStorage.getItem(Constants.NARRATIVE_DOWNLOAD_LIST);
    let downloads: any = [];
    if (nars) {
      downloads = JSON.parse(nars);
    }

    if (isDeletion) {
      let index = downloads?.findIndex(
        (it: any) => it.grantOppId == grantOppId
      );
      if (index >= 0) {
        downloads.splice(index, 1);
      }
    } else {
      let index = downloads?.findIndex(
        (it: any) => it.grantOppId == grantOppId
      );
      if (index == -1) {
        downloads.push({
          grantOppId: grantOppId,
          statusQueryString: statusQueryString,
          stateCode: stateCode,
          stateName: stateName,
          displayName: oppNumber
            ? `${oppNumber}-${stateName}`
            : `${grantOppId}-${stateName}`,
        });
      }
    }
    localStorage.setItem(
      Constants.NARRATIVE_DOWNLOAD_LIST,
      JSON.stringify(downloads)
    );
  }

  public getGrantsNarrativeDownloads() {
    const nars = localStorage.getItem(Constants.NARRATIVE_DOWNLOAD_LIST);
    if (nars) {
      try {
        let downloads = JSON.parse(nars);
        if (downloads && downloads.length > 0) {
          return downloads;
        }
      } catch (e) {}
    }
    return [];
  }

  public isAuthenticated(): any {
    const user = localStorage.getItem(Constants.TOKEN_STORE);
    if (user) {
      let tokenPayload = JSON.parse(user);
      if (tokenPayload.token && tokenPayload.refreshToken) {
        return tokenPayload; // will add interceptor to check the validity later.
      }
    }
    return null;
  }

  login(payload: any) {
    return this.http.post(`${environment.apiUrl}Accounts/login`, payload);
  }

  IDPLogin() {
    return this.http.post(
      `${environment.apiUrl}${Constants.ACCESS_TOKE_VALIDATION_API}${
        this.getTenantId() ?? null
      }`,
      null
    );
  }

  getUserOrgs() {
    return this.http.get(
      `${environment.apiUrl}${Constants.USER_ORGANI_VALIDATION_API}`
    );
  }

  getTenantId() {
    return this.getIssuers()?.orgId ?? null;
  }

  public refreshToken(token: string, refreshToken: string) {
    return this.http.post(`${environment.apiUrl}accounts/Refresh`, {
      accessToken: token,
      refreshToken: refreshToken,
    });
  }
}
