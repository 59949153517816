import { AuthConfig } from 'angular-oauth2-oidc';
import { Constants } from './constants';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: '',
  tokenEndpoint: '',
  clientId: '',
  scope: '',
  redirectUri: document.baseURI,
  postLogoutRedirectUri: document.baseURI + '#/home',
  logoutUrl: document.baseURI + '#/home',
  responseType: Constants.ACCESS_TOKEN_CONFIG,
  customQueryParams: {
    response_mode: 'query',
  },
  strictDiscoveryDocumentValidation: false,
  skipIssuerCheck: true,
  showDebugInformation: true, // !environment.production,
  requestAccessToken: false,
  useSilentRefresh: true,
  sessionChecksEnabled: true,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  timeoutFactor: 0.8,
  disableAtHashCheck: true,
  checkOrigin: true,
};
