<div class="wrapper">
  <div
    class="mb-3 d-flex flex-row justify-content-between align-items-center align-self-stretch"
  >
    <div class="headerText fw-bold" *ngIf="data.title">
      {{ data.title }}
    </div>
    <div *ngIf="!data?.title"></div>

    <img
      class="closeClass cursor-pointer"
      src="../../../assets/icons/svg/close.svg"
      alt="close"
      height="26"
      width="26"
      (click)="closeFn()"
    />
  </div>
  <div class="pb-4 textClass" *ngIf="data.message">{{ data.message }}</div>

  <ng-container *ngIf="data.isInnerHTML">
    <p class="mb-0">
      You are being redirected to an external web page:
      <span *ngIf="data.shortUrl"><br />{{ data.shortUrl }}</span>
    </p>
    <label class="checkbox ms-1 label-color"
      ><input
        type="checkbox"
        id="label-hf"
        (change)="onCheckBoxChanged($event)"
      />Don't ask me again</label
    >
  </ng-container>

  <div class="buttondiv pt-4">
    <button
      type="submit"
      class="cancel-button mb-1"
      (click)="closeFn()"
      *ngIf="data.negativeButtonMessage"
    >
      <!-- <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span> -->
      {{ data.negativeButtonMessage }}
    </button>
    <button
      type="submit"
      class="secondary-button mb-1 ml-3"
      (click)="secondaryButton()"
      *ngIf="data.secondaryButtonMessage"
    >
      <!-- <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span> -->
      {{ data.secondaryButtonMessage }}
    </button>
    <button
      type="submit"
      class="save-button mb-1 ms-3"
      (click)="saveFn()"
      *ngIf="data.postitionButtonMessage"
    >
      <!-- <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span> -->
      {{ data.postitionButtonMessage }}
    </button>
    <button
      type="submit"
      class="delete-button mb-1 ms-3"
      (click)="deleteFn()"
      *ngIf="data.deleteButtonMessage"
    >
      <!-- <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span> -->
      {{ data.deleteButtonMessage }}
    </button>
  </div>
</div>
