import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { MaterialAppModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { UserAgreementDialogComponent } from './user-agreement-dialog.component';

const routes: Routes = [
  {
    path: '',
    component: UserAgreementDialogComponent,
  },
];

@NgModule({
  declarations: [UserAgreementDialogComponent],
  exports: [UserAgreementDialogComponent],
  imports: [
    CommonModule,
    MaterialAppModule,
    FormsModule,
    NgxExtendedPdfViewerModule,
  ],
})
export class UserAgreementDialogModule {}
