<div class="dflexClass py-2 px-3" *ngIf="mergedArr">
  <div class="mt-1"><b>Peer County Map View</b></div>

  <img
    src="../../../assets/icons/svg/close.svg"
    alt="close"
    height="30"
    width="30"
    class="closeClass cursor-pointer"
    (click)="close()"
  />
</div>
<div class="row width">
  <div class="col-2" *ngIf="mergedArr">
    <div class="pr-1 pt-4 ps-3">
      <div class="mb-2 mt-2 fw-bold">Legend</div>
      <div class="mt-2 d-flex">
        <div class="boxClass me-2"></div>
        <span>CDC Group</span>
      </div>
      <div class="mt-2 d-flex">
        <div class="boxClass1 me-2"></div>
        <span>EY Group</span>
      </div>
      <div class="mt-2 d-flex">
        <div class="boxClass2 me-2"></div>
        <span>CDC and EY</span>
      </div>
      <div class="mt-4">
        <div class="mb-2 mt-2 fw-bold">Filter</div>
        <label class="container mb-2"
          ><span class="ms-1">CDC Group</span>
          <input
            type="checkbox"
            [checked]="filter.cdc"
            (click)="checkboxfn($event, 'cdc')"
          />
          <span class="checkmark"></span>
        </label>
        <label class="container"
          ><span class="ms-1">EY Group</span>
          <input
            type="checkbox"
            [checked]="filter.ey"
            (click)="checkboxfn($event, 'ey')"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-10 overflow-hidden" id="choropleth"></div>
</div>
<div #tooltip></div>
