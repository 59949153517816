import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/common/auth.service';
import { HealthAppService } from 'src/app/services/explore-health/health-app.service';
import { TenantService } from 'src/app/services/explore-health/tenant.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {
  fieldTextType = false;
  submitted: boolean = false;
  loading: boolean = false;

  errorMsg: string = '';
  title: string = 'Fetching Tenant Details..';
  isSuccess: boolean = false;

  formData: any = {
    email: '',
  };

  constructor(
    private router: Router,
    private auth: AuthService,
    private snack: SnackbarService,
    private haService: HealthAppService,
    private tenantService: TenantService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoginDialogComponent>
  ) {}

  ngOnInit(): void {
    this.onSubmit();
  }

  onSubmit() {
    this.errorMsg = '';
    this.submitted = true;
    this.loading = false;
    this.isSuccess = false;

    this.findTenentDetails();
    // this.legacyLogin();
  }

  legacyLogin() {
    if (this.formData.email?.length > 0 && this.formData.password?.length > 0) {
      this.loading = true;
      this.auth.login(this.formData).subscribe({
        next: (response: any) => {
          this.auth.hasLoggedIn = response;
          this.auth.hasAgreedTerns = response;
          this.succeedLogin(response);
          this.haService.userSettingsUpdated.next('true');
        },
        error: (error: any) => {
          this.setErrorMessage(error);
        },
        complete: () => {},
      });
    }
  }

  findTenentDetails() {
    this.loading = true;
    this.haService.getTenantDetails().subscribe({
      next: (tenants: any) => {
        if (tenants && tenants.length > 0) {
          this.tenantService.initiateCodeFlow(
            this.tenantService.formAuthConfig(tenants[0], true),
            true,
            true
          );
          this.title = `Tenant  —  "${tenants[0].displayName}"`;
          this.errorMsg =
            'Tenant details received successfully, please wait while we redirect you to the login page..';
          this.isSuccess = true;
        } else {
          this.loading = false;
          this.snack.openErrorBar('Failed to find tenant details.');
          this.close();
        }
      },
      error: (error: any) => {
        this.setErrorMessage(error);
        this.snack.openErrorBar(String(this.errorMsg));
        this.close();
      },
      complete: () => {},
    });
  }

  succeedLogin(response: any) {
    this.loading = false;
    this.auth.showLoginModal = false;
    this.dialogRef.close({
      response: response,
    });
    // this.haService.userSuccessfulLogin.next(true);
    this.router.navigate(['/home']);
  }

  ngOnDestroy() {
    this.formData = {
      email: '',
    };
  }

  setErrorMessage(error: any) {
    this.loading = false;
    if (error.error?.title) {
      this.errorMsg = error.error.title;
    } else {
      if (error.status === 500) {
        this.errorMsg = error.statusText ?? 'Internal Server-Error';
      } else {
        this.errorMsg = 'Sorry, something went wrong!';
      }
    }
  }

  close() {
    this.dialogRef.close();
  }
}
