import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { HealthAppService } from '../explore-health/health-app.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  constructor(public auth: AuthService, public router: Router,private haService:HealthAppService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.auth.hasLoggedIn) {
      this.auth.showLoginModal = true;
      this.haService.openModal();
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }
}
