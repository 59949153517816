<header
  class="header flex-center-middle"
  [ngClass]="{
    homeHeader: router.url == '/home',
    grantsHeader: checkIfGrantDetailsPage(),
    headerPage:
      router.url == '/state-health' ||
      router.url == '/county-health' ||
      router.url == '/about'
  }"
>
  <div
    class="logo-container flex-center-middle pointer-mouse"
    (click)="goHome()"
  >
    <!-- <div class="topnav"> -->
    <a class="textdec-none pointer-mouse flex-value-0">
      <img
        class="logo-icon pull-left pb-2"
        src="assets/icons/svg/HeartLogo.svg"
        alt="Webiste logo"
    /></a>
    <h1 class="logo pointer-mouse">
      <!-- [ngClass]="{
      'logo-tenant': isTenantNameAvailable()
    }" -->
      <a class="HeaderClass">
        <!-- [ngClass]="{
          'tenant-font-size': isTenantNameAvailable()
        }" -->
        EY Impact</a
      >
      <!-- <a *ngIf="isTenantNameAvailable()" class="tenant-headerClass">{{
        getTenantName()
      }}</a> -->
    </h1>
  </div>
  <ul class="main-nav flex-center-middle" *ngIf="!hideHeaderItems">
    <li
      class="wgt-150px"
      [matMenuTriggerFor]="dropdown"
      #menuTriggerData="matMenuTrigger"
      *ngIf="haService?.healthSetData?.length > 0"
    >
      <a class="nav-menu-items labels d-flex item"
        ><span class="alignText label-color lables pddg-btm-2px"
          >Explore Data</span
        >
        <span
          class="dropdown-icon"
          [ngClass]="{
            'dropdown-icon-rotate': menuTriggerData.menuOpen
          }"
        ></span>
      </a>
      <mat-menu #dropdown="matMenu">
        <div class="dropdown-container" (click)="$event.stopPropagation()">
          <ng-scrollbar
            appearance="compact"
            [autoHeightDisabled]="true"
            [autoWidthDisabled]="true"
            class="scroll-container hgt-75px"
          >
            <button
              mat-menu-item
              class="drp-height pt-2 pb-1"
              *ngFor="let data of haService.healthSetData"
              (click)="menuTriggerData.closeMenu(); clearStore(data.datasetId)"
            >
              <span class="hor-scroll">
                {{ data.name }}
              </span>
            </button>
          </ng-scrollbar>
        </div>
      </mat-menu>
    </li>

    <li
      class="wgt-100px"
      [matMenuTriggerFor]="dropdownGrant"
      #menuTriggerGrant="matMenuTrigger"
    >
      <a class="nav-menu-items labels d-flex item"
        ><span class="alignText label-color lables pddg-btm-2px">Grants</span>
        <span
          class="dropdown-icon"
          [ngClass]="{
            'dropdown-icon-rotate': menuTriggerGrant.menuOpen
          }"
        ></span>
      </a>
      <mat-menu #dropdownGrant="matMenu">
        <div class="dropdown-container" (click)="$event.stopPropagation()">
          <ng-scrollbar
            appearance="compact"
            [autoHeightDisabled]="true"
            [autoWidthDisabled]="true"
            class="scroll-container hgt-4rem"
          >
            <button
              mat-menu-item
              class="drp-height pt-2 pb-1"
              routerLink="/grants-dashboard"
              (click)="menuTriggerGrant.closeMenu()"
            >
              <span class="hor-scroll"> Find Grants </span>
            </button>
            <button
              mat-menu-item
              class="drp-height pt-2"
              routerLink="/executive-dashboard"
              (click)="menuTriggerGrant.closeMenu()"
            >
              <span class="hor-scroll"> Grants Dashboard </span>
            </button>
          </ng-scrollbar>
        </div>
      </mat-menu>
    </li>

    <li
      class="drpdwn-menu drpdwn-width"
      [matMenuTriggerFor]="dropdown"
      #menuTrigger="matMenuTrigger"
      *ngIf="auth.hasLoggedIn"
    >
      <a class="nav-menu-items labels d-flex item"
        ><span class="alignText label-color lables pddg-btm-2px"
          >Saved Searches</span
        >
        <span
          class="dropdown-icon"
          [ngClass]="{
            'dropdown-icon-rotate': menuTrigger.menuOpen
          }"
        ></span>
      </a>
      <mat-menu #dropdown="matMenu">
        <div class="dropdown-container" (click)="$event.stopPropagation()">
          <ng-scrollbar
            appearance="compact"
            [autoHeightDisabled]="true"
            [autoWidthDisabled]="true"
            class="scroll-container hgt-13-5rem"
          >
            <ng-container>
              <div
                mat-menu-item
                class="drp-height pt-1 settings-item"
                (click)="goToSettings('searches'); menuTrigger.closeMenu()"
              >
                Manage Saved Searches
              </div>
            </ng-container>

            <ng-container *ngIf="haService.savedSearchData?.length > 0">
              <ng-container *ngFor="let data of haService.savedSearchData">
                <button
                  mat-menu-item
                  class="drp-height pt-1"
                  (click)="menuTrigger.closeMenu(); openCountyExplore(data)"
                >
                  <span
                    class="hor-scroll"
                    matTooltip="{{ data.settingsName }}"
                    matTooltipPosition="left"
                    [matTooltipClass]="'header-saved-search-item'"
                  >
                    {{ data.settingsName }}</span
                  >
                </button>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!savedSearchdata?.length">
              <button
                mat-menu-item
                class="drp-height pt-1 d-flex align-center justify-content-center"
              >
                <i class="ft-13">No Saved Searches</i>
              </button>
            </ng-container>
          </ng-scrollbar>
        </div>
      </mat-menu>
    </li>

    <li
      class="drpdwn-menu drpdwn-width wgt-120px"
      [matMenuTriggerFor]="dropdown"
      #menuTrigger="matMenuTrigger"
      *ngIf="auth.hasLoggedIn && isActiveDownloadsAvailable()"
    >
      <a class="nav-menu-items labels d-flex item"
        ><span class="alignText label-color lables pddg-btm-2px"
          >Downloads</span
        >
        <span
          class="dropdown-icon"
          [ngClass]="{
            'dropdown-icon-rotate': menuTrigger.menuOpen
          }"
        ></span>
      </a>
      <mat-menu #dropdown="matMenu">
        <div class="dropdown-container" (click)="$event.stopPropagation()">
          <ng-scrollbar
            appearance="compact"
            [autoHeightDisabled]="true"
            [autoWidthDisabled]="true"
            class="scroll-container hgt-18rem"
          >
            <ng-container *ngFor="let data of activeDownloads">
              <button
                mat-menu-item
                class="drp-height pt-1 border-bottom"
                (click)="menuTrigger.closeMenu(); checkNarrativeDownload(data)"
              >
                <span
                  class="hor-scroll hyperlink"
                  matTooltip="{{
                    data.displayName
                  }} -- Click to check the download status."
                  matTooltipPosition="left"
                  [matTooltipClass]="'header-saved-search-item'"
                >
                  {{ data.displayName }}</span
                >
              </button>
            </ng-container>
          </ng-scrollbar>
        </div>
      </mat-menu>
    </li>

    <li
      class="drpdwn-menu"
      *ngIf="auth.hasLoggedIn"
      [matMenuTriggerFor]="dropdown"
      #menuTrigger="matMenuTrigger"
    >
      <a class="nav-menu-items d-flex item"
        ><span class="alignText ft-bold pddg-btm-2px">My Account</span>
        <span
          class="dropdown-icon"
          [ngClass]="{
            'dropdown-icon-rotate': menuTrigger.menuOpen
          }"
        ></span>
      </a>
      <mat-menu #dropdown="matMenu">
        <div class="dropdown-container" (click)="$event.stopPropagation()">
          <button mat-menu-item class="border-bottom" (click)="toggleLogin()">
            Log Out
            <span
              *ngIf="isLoggingOut"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
          </button>

          <button
            mat-menu-item
            class="drp-height pb-1 mb-1 pt-1"
            routerLink="/about"
            routerLinkActive="active"
            (click)="auth.showLoginModal = false; menuTrigger.closeMenu()"
          >
            About
          </button>
          <button
            mat-menu-item
            class="drp-height pb-1 pt-1"
            (click)="goToSettings('searches'); menuTrigger.closeMenu()"
          >
            My Settings
          </button>
          <button
            mat-menu-item
            class="drp-height pb-1 pt-1"
            (click)="goToSettings('support'); menuTrigger.closeMenu()"
          >
            Support Requests
          </button>
        </div>
      </mat-menu>
    </li>

    <li *ngIf="!auth.hasLoggedIn">
      <a
        class="nav-menu-items login"
        [ngClass]="auth.showLoginModal ? 'active' : ''"
        (click)="toggleLogin()"
        >Login</a
      >
    </li>
  </ul>
</header>
