import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, uniqBy } from 'lodash';
import { LoaderService } from 'src/app/components/loader/loader.service';
import { AuthService } from 'src/app/services/common/auth.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { HealthAppService } from 'src/app/services/explore-health/health-app.service';
import { Constants } from 'src/app/utils/constants';

@Component({
  selector: 'app-support-request-dialog',
  templateUrl: './support-request-dialog.component.html',
  styleUrls: ['./support-request-dialog.component.scss'],
})
export class SupportRequestDialogComponent implements OnInit {
  loading = false;
  acceptFileTypes = Constants.ATTACHMENTALLFILETYPES;
  selectedFiles: File[] = [];
  maxAllowedSize = 2097152;

  newRequesPayload: any = {
    requestId: 0,
    status: 'In Progress',
    priority: '',
    subject: '',
    description: '',
    resolution: '',
    fileUploads: [],
  };

  isAdmin: boolean = false;

  constructor(
    public haService: HealthAppService,
    public loader: LoaderService,
    @Inject(MAT_DIALOG_DATA) public dataInstance: any,
    public dialogRef: MatDialogRef<SupportRequestDialogComponent>,
    private auth: AuthService,
    private snack: SnackbarService
  ) {
    this.isAdmin = this.auth.isAdmin();
  }

  ngOnInit(): void {
    if (this.dataInstance.isUpdating) {
      this.newRequesPayload = cloneDeep(this.dataInstance.dataForEdit);
    }
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  create() {
    this.loader.showLoader();

    if (this.dataInstance.isUpdating && !this.isAdmin) {
      this.newRequesPayload.status = 'In Progress';
    }

    this.haService
      .createSupportRequest(
        this.newRequesPayload,
        this.selectedFiles,
        this.dataInstance.isUpdating
      )
      .subscribe({
        next: (response: any) => {
          this.loader.hideLoader();
          this.dialogRef.close({
            refresh: true,
          });
        },
        error: (error) => {
          this.loader.hideLoader();
          if (error?.error?.error?.length > 0) {
            this.snack.openErrorBar(error.error.error.join(','));
          }
        },
        complete: () => {},
      });
  }

  handleFileInputClick(filesInputEvent: any) {
    if (filesInputEvent) {
      filesInputEvent.target.value = '';
    }
  }

  clearUploadedFiles() {
    this.selectedFiles = [];
  }

  handleFileInput(filesInputEvent: any) {
    if (filesInputEvent) {
      if (!this.selectedFiles) {
        this.selectedFiles = [];
      }

      let temp: any = [];
      for (var i = 0; i < filesInputEvent.target.files.length; i++) {
        temp.push(filesInputEvent.target.files[i]);
      }

      if (
        !temp?.every(
          (it: any) =>
            String(it.name).endsWith('.docx') ||
            String(it.name).endsWith('.doc') ||
            it.type == 'image/png' ||
            it.type == 'image/jpeg' ||
            it.type == 'application/pdf'
        )
      ) {
        this.snack.openInfoBar(
          'Only .pdf,.docx,.jpg,.png,.jpeg, extensions are allowed!',
          'Ok',
          10
        );
        return;
      }

      let count = this.selectedFiles.length + temp.length;
      if (this.newRequesPayload?.isUpdating) {
        count = count + this.newRequesPayload?.fileUploads?.length;
      }
      if (count > 2) {
        this.snack.openInfoBar(
          'Maximum number of files allowed are two only!',
          'Ok',
          10
        );
        return;
      }

      for (var i = 0; i < this.selectedFiles.length; i++) {
        temp.push(this.selectedFiles[i]);
      }

      let greaterThan1Mb: boolean = false;
      temp.forEach((it: any) => {
        if (it.size > this.maxAllowedSize) {
          greaterThan1Mb = true;
          return;
        }
      });

      if (greaterThan1Mb) {
        this.snack.openInfoBar(
          `Maximum allowed file size is ${Math.floor(
            this.maxAllowedSize / 1048576
          )} MB per attachment!`,
          'Ok',
          10
        );
        return;
      }

      this.selectedFiles = [
        ...this.selectedFiles,
        ...filesInputEvent.target.files,
      ];

      this.selectedFiles = uniqBy(this.selectedFiles, 'name'); //.filter((it: File)=> );
    }
  }

  validPayload() {
    return (
      !this.newRequesPayload.priority ||
      !this.newRequesPayload.subject ||
      !this.newRequesPayload.description
    );
  }

  openFile(file: any) {
    window.open(file.downloadUrl, '_blank');
  }
}
