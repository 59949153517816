<div class="wrapper">
  <div class="px-5 py-3">
    <div class="headerText pb-3">
      <span *ngIf="!data.isUpdating || data.fromExplore"
        >Add a new saved search</span
      >
      <span *ngIf="data.isUpdating && !data.fromExplore"
        >Edit saved search</span
      >
    </div>
    <div class="mt-3">
      <div class="headerClass mb-4">
        {{ data.isUpdating ? "" : "SELECT A LEVEL" }}
      </div>
      <div class="mb-4 d-flex radio-grp" *ngIf="!data.isUpdating">
        <label class="container"
          >State level
          <input
            type="radio"
            [checked]="!countyBasedInput && !isDisparity"
            name="radio"
            (click)="radioChange('state')"
            [disabled]="data.isUpdating"
          />
          <span class="checkmark"></span>
        </label>
        <label class="container ms-4"
          >County level
          <input
            type="radio"
            [checked]="countyBasedInput && !isDisparity"
            name="radio"
            (click)="radioChange('county')"
            [disabled]="data.isUpdating"
          />
          <span class="checkmark"></span>
        </label>
        <label class="container ms-4"
          >Disparity level
          <input
            type="radio"
            [checked]="isDisparity"
            name="radio"
            (click)="radioChange('disparity')"
            [disabled]="data.isUpdating"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="isDisparity">
        <div class="col-6">
          <span class="mt-4">
            <label
              class="filter-title verticalAlign filter-title-secondary-padding col-12 mb-4"
              ><span class="headerClass">Disparity View</span>
              <div class="tooltip-definition">
                Tooltip definition would go here
              </div></label
            >
            <mat-form-field floatLabel="always" class="disparityWidth">
              <div class="align-arrow">
                <img
                  src="../../../assets/icons/svg/users.svg"
                  alt="map-pin"
                  class="me-2"
                />
                <mat-select
                  #disViewSelect
                  panelClass="testClass1"
                  class="mater-selection"
                  name="disparity"
                  placeholder="Disparity view"
                  id="disparity"
                  [(ngModel)]="selectedDisparityView"
                  (ngModelChange)="onDisparityChange($event)"
                  [ngClass]="{
                    'rotate-down-arrow-icon': disViewSelect.panelOpen
                  }"
                >
                  <ng-scrollbar
                    appearance="compact"
                    [autoHeightDisabled]="true"
                    [autoWidthDisabled]="true"
                    class="scroll-container hgt-9rem"
                  >
                    <mat-option value="Demographic Disparity"
                      >Demographic Disparity</mat-option
                    >
                    <mat-option value="Geographic Disparity"
                      >Geographic Disparity</mat-option
                    >
                  </ng-scrollbar>
                </mat-select>
              </div>
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="col-6">
        <span class="mt-4">
          <label class="filter-title pop-adj resolve-label-flex col-12 mb-4"
            ><span class="headerClass">SELECT A STATE</span>
            <div class="tooltip-definition">
              Tooltip definition would go here
            </div></label
          >
          <mat-form-field floatLabel="always" class="stateCountyWidth">
            <div class="align-arrow">
              <img
                src="../../../assets/icons/svg/map-pin.svg"
                alt="map-pin"
                class="me-2"
              />
              <mat-select
                #stateSelect
                panelClass="testClass1"
                class="mater-selection"
                name="state"
                id="state"
                placeholder="State"
                [value]="stateSelected?.stateName"
                (selectionChange)="stateValueChanged($event)"
                [ngClass]="{
                  'rotate-down-arrow-icon': stateSelect.panelOpen
                }"
              >
                <label class="align-arrow">
                  <input
                    id="search-state"
                    class="input-style border-bottom"
                    type="text"
                    placeholder="search here"
                    [formControl]="stateCtrl"
                  />
                  <img
                    class="icon-color me-2"
                    src="../../../assets/icons/svg/search-icon.png"
                    alt="search"
                    height="30"
                    width="30"
                  />
                </label>

                <ng-scrollbar
                  appearance="compact"
                  [autoHeightDisabled]="true"
                  [autoWidthDisabled]="true"
                  class="scroll-container hgt-12rem"
                >
                  <mat-option
                    *ngFor="let s of filteredStates | async"
                    [value]="s.stateName"
                  >
                    {{ s.stateName }}
                  </mat-option>
                </ng-scrollbar>
              </mat-select>
            </div>
          </mat-form-field>
          <p *ngIf="error.stateError" class="mb-2 ms-2 errorDropdownText">
            Please choose a valid state
          </p>
        </span>
      </div>
      <div class="col-6" *ngIf="countyBasedInput">
        <span class="mt-4">
          <label class="filter-title pop-adj resolve-label-flex col-12 mb-4"
            ><span class="headerClass">SELECT A COUNTY</span>
            <div class="tooltip-definition">
              Tooltip definition would go here
            </div></label
          >
          <mat-form-field
            appearance="fill"
            floatLabel="always"
            class="stateCountyWidth"
          >
            <div class="align-arrow">
              <img
                src="../../../assets/icons/svg/map-pin.svg"
                alt="map-pin"
                class="me-2"
              />
              <mat-select
                #countySelect
                class="mater-selection"
                name="county"
                placeholder="County"
                id="county"
                panelClass="testClass"
                [value]="countySelected?.countyName"
                (selectionChange)="countyValueChanged($event)"
                [ngClass]="{
                  'rotate-down-arrow-icon': countySelect.panelOpen
                }"
              >
                <label class="align-arrow">
                  <input
                    id="search-state"
                    class="input-style border-bottom"
                    type="text"
                    placeholder="search here"
                    [formControl]="countyCtrl"
                  />
                  <img
                    class="icon-color me-2"
                    src="../../../assets/icons/svg/search-icon.png"
                    alt="search"
                    height="30"
                    width="30"
                  />
                </label>

                <ng-scrollbar
                  appearance="compact"
                  [autoHeightDisabled]="true"
                  [autoWidthDisabled]="true"
                  class="scroll-container hgt-12rem"
                >
                  <mat-option
                    *ngFor="let c of filteredCounty | async"
                    [value]="c.countyName"
                  >
                    {{ c.countyName }}
                  </mat-option>
                </ng-scrollbar>
              </mat-select>
            </div>
          </mat-form-field>
          <p *ngIf="error.countyError" class="mb-2 ms-2 errorDropdownText">
            Please choose a valid county
          </p>
        </span>
      </div>
      <div class="col-6" *ngIf="!isDisparity">
        <span class="mt-4">
          <label
            class="filter-title verticalAlign filter-title-secondary-padding col-12 mb-4"
            ><span class="headerClass">COMPARISON</span>
            <div class="tooltip-definition">
              Tooltip definition would go here
            </div></label
          >
          <mat-form-field floatLabel="always" class="peerGroupWidth">
            <div class="align-arrow">
              <img
                src="../../../assets/icons/svg/users.svg"
                alt="map-pin"
                class="me-2"
              />
              <mat-select
                #peerSelect
                panelClass="testClass1"
                class="mater-selection"
                name="county"
                placeholder="Compare group"
                id="county"
                [(ngModel)]="selectedDataset"
                (ngModelChange)="onPeerGroupChange($event)"
                [ngClass]="{
                  'rotate-down-arrow-icon': peerSelect.panelOpen
                }"
              >
                <ng-scrollbar
                  appearance="compact"
                  [autoHeightDisabled]="true"
                  [autoWidthDisabled]="true"
                  class="scroll-container hgt-9rem"
                >
                  <mat-option value="cdc">CDC Peers</mat-option>
                  <mat-option value="ey">EY Peers</mat-option>
                  <mat-option *ngIf="countyBasedInput" value="state"
                    >State</mat-option
                  >
                  <mat-option
                    *ngIf="
                      countyBasedInput && stateSelected?.stateCode != 'MBPROXY'
                    "
                    value="national"
                    >National</mat-option
                  >
                </ng-scrollbar>
              </mat-select>
            </div>
          </mat-form-field>
        </span>
      </div>
      <div
        class="col-6"
        *ngIf="!isDisparity"
        [ngClass]="{ 'd-none': countyBasedInput }"
      >
        <div class="resolve-flex">
          <label
            class="filter-title pop-adj resolve-label-flex col-12 mb-4 pb-2"
            ><span class="headerClass">POPULATION ADJUSTMENT</span>
            <div class="tooltip-definition">
              Tooltip definition would go here
            </div></label
          >
          <span class="pr-20 mt-3"
            ><label
              class="toggle-label"
              [ngClass]="{ 'fw-bold': !selectedPopAdj }"
              >Off</label
            >
            <label class="switch ms-2 me-2">
              <input
                type="checkbox"
                [checked]="selectedPopAdj"
                (change)="fnPopAdjChange($event)"
              />
              <span class="slider round"></span> </label
            ><label
              class="ms-1 toggle-label"
              [ngClass]="{ 'fw-bold': selectedPopAdj }"
              >On</label
            >
          </span>
        </div>
      </div>
      <div class="col-6" *ngIf="!isDisparity">
        <div class="resolve-flex">
          <label
            class="filter-title pop-adj resolve-label-flex col-12 mb-4 pb-2"
            ><span class="headerClass">WEIGHTED SCORES</span>
            <div class="tooltip-definition">
              Tooltip definition would go here
            </div></label
          >
          <span class="pr-20 mt-3"
            ><label
              class="toggle-label"
              [ngClass]="{ 'fw-bold': !selectedWghtedScores }"
              >Off</label
            >
            <label class="switch ms-2 me-2">
              <input
                type="checkbox"
                [checked]="selectedWghtedScores"
                (change)="fnWeightedScoreChange($event)"
              />
              <span class="slider round"></span> </label
            ><label
              class="ms-1 toggle-label"
              [ngClass]="{ 'fw-bold': selectedWghtedScores }"
              >On</label
            >
          </span>
        </div>
      </div>
      <div class="col-12">
        <span class="mt-0">
          <label
            class="filter-title pop-adj resolve-label-flex col-12 mb-4"
            [ngClass]="{ 'pt-4': !countyBasedInput }"
            ><span class="headerClass">SAVED SEARCH NAME</span>
            <div class="tooltip-definition">Instructional text</div></label
          >
          <input
            class="TextInput"
            [(ngModel)]="searchName"
            type="text"
            (ngModelChange)="SearchnameFn()"
            placeholder="Enter a name"
          />
          <p *ngIf="error.searchNameError" class="errorClass mb-0 ms-2">
            Please enter a valid name
          </p>
        </span>
      </div>
    </div>
    <div class="btn-grp mt-5">
      <button
        type="submit"
        class="cancel-button mb-3"
        (click)="cancelClicked()"
      >
        Cancel
      </button>
      <button type="submit" class="save-button mb-3 ms-3" (click)="saveFn()">
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        {{ data.isUpdating && !data.fromExplore ? "Update" : "Save" }}
      </button>
    </div>
  </div>
</div>
