import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Filter, IFilter } from 'src/app/interface/filter-interface';
import { AuthService } from '../common/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ExploreHealthService {
  public stateFilterData: Filter = {
    selectedDataSet: 'cdc',
    selectedPopAdj: false,
    selectedWghtedScores: false,
    selectedNation: false,
    tableView: false,
    chartView: false,
  };
  public countyFilterData: Filter = {
    selectedDataSet: 'cdc',
    selectedPopAdj: false,
    selectedWghtedScores: false,
    selectedNation: false,
    tableView: false,
    chartView: false,
  };
  public filterData: IFilter = {
    selectedDataSet: 'cdc',
    selectedPopAdj: false,
    selectedWghtedScores: false,
    disparityView: 'Demographic Disparity',
  };

  public selectedStateChartDisplay: string = 'hf';
  public loader: boolean = false;
  public loader2: boolean = false;
  public loadFilterData: boolean = false;
  public settings: any;
  public isCountySelected: boolean = false;
  public isStateOrCounty: any;
  public countyList: any;

  public selectedStateFilter = new Subject();
  public selectedWghtedFilter = new Subject();
  public selectedCountyFilter = new Subject();
  public selectedSavedSearch = new Subject();
  public countySubSummaryTabIndex: number = 0;
  public healthFactorSelectionUpdated = new BehaviorSubject<any>(null);
  public stateDataSet = new BehaviorSubject<any>(null);
  currentDataSet = this.stateDataSet.asObservable();

  public disparityView = new BehaviorSubject<any>(null);
  disparityViewObservable = this.disparityView.asObservable();

  constructor(private auth: AuthService) {}

  clearInstance() {
    this.stateFilterData = {
      selectedDataSet: 'cdc',
      selectedPopAdj: false,
      selectedWghtedScores: false,
      selectedNation: false,
      tableView: false,
      chartView: false,
    };
    this.countyFilterData = {
      selectedDataSet: 'cdc',
      selectedPopAdj: false,
      selectedWghtedScores: false,
      selectedNation: false,
      tableView: false,
      chartView: false,
    };
    this.filterData = {
      selectedDataSet: 'cdc',
      selectedPopAdj: false,
      selectedWghtedScores: false,
      disparityView: 'Demographic Disparity',
    };

    this.selectedStateChartDisplay = 'hf';
    this.loader = false;
    this.loader2 = false;
    this.loadFilterData = false;
    this.settings = null;
    this.isCountySelected = false;
    this.isStateOrCounty = null;
    this.countyList = null;

    this.countySubSummaryTabIndex = 0;
  }

  public getLocalUserSetting() {
    return this.auth.getUserSetting();
  }
  public setLocalUserSetting(payload: any) {
    this.auth.setSettingsLocally(payload);
  }
  public updateStateFilter() {
    //this.setLocalUserSetting(this.getPayload());
    this.selectedStateFilter.next(true);
  }
  public updateWghtedFilter() {
    //this.setLocalUserSetting(this.getPayload());
    this.selectedWghtedFilter.next(true);
  }
  public updateCountyFilter() {
    //this.setLocalUserSetting(this.getPayload());
    this.selectedCountyFilter.next(true);
  }
  public getDataSet(data: any, isCountyBased?: boolean) {
    //this.setLocalUserSetting(this.getPayload(isCountyBased));
    this.stateDataSet.next(data);
  }
  public updateSavedSearch(param: any) {
    this.selectedSavedSearch.next(param);
  }

  public onDisparityViewChanged(disparity: any) {
    this.disparityView.next(disparity);
  }

  getPayload(isCountyBased?: boolean): any {
    let payload = {
      stateFilterData: {
        selectedDataSet: this.stateFilterData.selectedDataSet,
        selectedPopAdj: this.stateFilterData.selectedPopAdj,
        selectedWghtedScores: this.stateFilterData.selectedWghtedScores,
      },
      countyFilterData: {
        selectedDataSet: this.countyFilterData.selectedDataSet,
        selectedWghtedScores: this.countyFilterData.selectedWghtedScores,
      },
    };
    return payload;
  }
}
