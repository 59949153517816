import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MaterialAppModule } from './material.module';
import { LoaderService } from './components/loader/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { AuthInterceptor } from './services/common/auth.interceptor';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { D3ChoroplethMapComponent } from './pages/d3-choropleth-map/d3-choropleth-map.component';
import { SavedSearchAddModule } from './pages/saved-search-add/saved-search-add.module';
import { UserAgreementDialogModule } from './pages/user-agreement-dialog/user-agreement-dialog.module';
import { SupportRequestDialogModule } from './pages/support-request-dashboard/support-request-dialog/support-request-dialog.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { InsightsModule } from './services/insights/insights.module';
import { CSP_NONCE } from '@angular/core';
import { Constants } from './utils/constants';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    D3ChoroplethMapComponent,
  ],
  imports: [
    BrowserModule,
    InsightsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialAppModule,
    SavedSearchAddModule,
    OAuthModule.forRoot(),
    BrowserAnimationsModule,
    UserAgreementDialogModule,
    SupportRequestDialogModule,
  ],
  providers: [
    LoaderService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: CSP_NONCE,
      useValue: Constants.CSP_NOUNCE,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
