import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from 'src/app/components/loader/loader.service';
import { environment } from '../../../environments/environment';
import { LoginDialogComponent } from 'src/app/pages/login-dialog/login-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { D3ChoroplethMapComponent } from 'src/app/pages/d3-choropleth-map/d3-choropleth-map.component';
import { SavedSearchAddComponent } from 'src/app/pages/saved-search-add/saved-search-add.component';
import { AuthService } from '../common/auth.service';
import { AlertpopupComponent } from 'src/app/components/alertpopup/alertpopup.component';
import { marked } from 'marked';
import {
  AvailableDataSets,
  Constants,
  InsightCustomEvents,
} from 'src/app/utils/constants';
import { GrantPopupComponent } from 'src/app/pages/grants-dashboard-tab/grant-popup/grant-popup.component';
import { formatNumber } from '@angular/common';
import { cloneDeep } from 'lodash';
import { Insights } from '../insights/insights.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HealthAppService {
  public static STATES_LIST: any = [];
  private matLoginDialogRef: MatDialogRef<any> | undefined;
  private maAgreementDialogRef: MatDialogRef<any> | undefined;
  addOrEditSavedSearch: boolean = false;
  stateDisparity: Subscription | undefined;
  public healthSetData: any = [];

  set stateList(states: any) {
    HealthAppService.STATES_LIST = states;
  }

  get stateList() {
    return HealthAppService.STATES_LIST != null &&
      HealthAppService.STATES_LIST.length > 0
      ? HealthAppService.STATES_LIST
      : [];
  }

  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private dialog: MatDialog,
    private auth: AuthService,
    private insights: Insights,
    private sanitizer: DomSanitizer
  ) {
    // this.getDataSets();
  }

  getDataSets(clearDataSets: boolean = false) {
    if (clearDataSets) {
      this.healthSetData = [];
    }
    if (!this.healthSetData?.length) {
      this.getApplicationDataSets().subscribe((data: any) => {
        if (data && data?.length > 0) {
          this.healthSetData = data;
        }
      });
    }
  }

  public stateHealthFactors: any = [];
  public disparityStateSelectedFactors: any = [];
  public stateCountyHealthFactors: any = [];
  public countyHealthFactors: any = [];
  public stateHealthOutcomes: any = [];
  public selectedState: any;
  public countySelected: any;
  public savedSearchData: any = [];
  public isFavourites: any = false;
  public stateChanged = new Subject();
  public healthFactorDataRefreshed = new Subject();
  public userSuccessfulLogin = new Subject();
  public showProgressSpinner = false;
  public showPopulationAdjustment = true;
  public selectedHealthFilter = { selectedHf: '', sortValue: '' };
  public strategySort: any;
  public strategySearch: any = '';
  public healthDataSet: number = AvailableDataSets.PublicHealth;
  public dataViewSelected = 'factorsView';
  public selectedLevel = 0;
  public strategypageSize: any = 10;
  public addeditSaved = new Subject();
  public userSettingsUpdated = new Subject();
  public outcomeFilterScrollSubject = new Subject();
  public disparityFilterScrollSubject = new Subject();
  public HfInitialSelection = false;
  public viewStrategiesGrantsLinkSubject = new Subject();
  public onExploreStateClear = new Subject();

  clearInstance(dataSet: number) {
    this.addOrEditSavedSearch = false;
    this.stateHealthFactors = [];
    this.disparityStateSelectedFactors = [];
    this.stateCountyHealthFactors = [];
    this.countyHealthFactors = [];
    this.stateHealthOutcomes = [];
    this.selectedState = null;
    this.countySelected = null;
    // this. savedSearchData: any = [];
    this.isFavourites = false;
    this.showProgressSpinner = false;
    this.selectedHealthFilter = { selectedHf: '', sortValue: '' };
    this.strategySort = null;
    this.strategySearch = '';
    this.dataViewSelected = 'factorsView';
    this.healthDataSet = dataSet;
    this.strategypageSize = 10;
    this.HfInitialSelection = false;
  }

  clearExploreState(forceClear: boolean) {
    this.onExploreStateClear.next({ clearState: forceClear ? 'yes' : 'no' });
  }

  getCountiesByStateName(stateName: any, showProgress: boolean = true) {
    return new Promise((resolve, reject) => {
      if (showProgress) {
        this.loader.showLoader();
      }
      this.getCountyMasterData(stateName).subscribe({
        next: (response: any) => {
          resolve(response);
        },
        error: (error: any) => {
          resolve([]);
          console.error(error);
        },
        complete: () => {
          this.loader.hideLoader();
          // console.log('Request completed');
        },
      });
    });
  }

  getMapView(countycode: any, filterType: any, showProgress: boolean = true) {
    return new Promise((resolve, reject) => {
      if (showProgress) {
        this.loader.showLoader();
      }
      this.getPeerCountyMapView(countycode, filterType).subscribe({
        next: (response: any) => {
          resolve(response);
        },
        error: (error: any) => {
          resolve([]);
          console.error(error);
        },
        complete: () => {
          this.loader.hideLoader();
          // console.log('Request completed');
        },
      });
    });
  }

  getCountyMasterData(stateName: any) {
    return this.http.get(
      `${environment.apiUrl}Analytics/county-list${
        stateName ? '?stateName=' + stateName : ''
      }`
    );
  }

  getStatesMasterData(state?: any) {
    return this.http.get(`${environment.apiUrl}Analytics/state-list`);
  }

  getStateHealthIndicatorData(stateName: any, factorType: any) {
    return this.http.get(
      `${environment.apiUrl}Analytics/statehealthindicator?stateCode=${stateName}&factorType=${factorType}`
    );
  }
  getStateMentalHealthIndicatorData(
    stateName: any,
    factorType: any = 'HealthFactors'
  ) {
    return this.http.get(
      `${environment.apiUrl}Analytics/statementalhealthindicator?stateCode=${stateName}&factorType=${factorType}`
    );
  }
  getStateHealthData(stateName: any) {
    return this.http.get(
      `${environment.apiUrl}Analytics/statehealth?stateCode=${stateName}`
    );
  }
  getMentalStateHealthData(stateName: any) {
    return this.http.get(
      `${environment.apiUrl}Analytics/statementalhealth?stateCode=${stateName}`
    );
  }
  getCountyHealthData(countycode: any, payload: any) {
    return this.http.post(
      `${environment.apiUrl}Analytics/countyhealth/${String(
        countycode
      ).trim()}` + (this.selectedState?.stateCode == 'MBPROXY' ? '/1' : '/0'),
      payload
    );
  }
  getCountyPeerRanking(payload: any) {
    if (
      this.selectedState?.stateCode == 'MBPROXY' &&
      payload.filterType == 'state'
    ) {
      payload.filterType = 'ProxyState';
    }
    return this.http.post(
      `${environment.apiUrl}county/peerranking`,
      //  +(this.selectedState?.stateCode == 'MBPROXY' ? '/1' : '/0')
      payload
    );
  }

  getNationalState(factorCd: any, dataSetId: any) {
    return this.http.post(
      `${environment.apiUrl}analytics/nationalhealth/state`,
      {
        factorcd: factorCd ?? null,
        dataSetId: dataSetId,
      }
    );
  }

  getNationalCounty(factorCd: any, dataSetId: any) {
    return this.http.post(
      `${environment.apiUrl}analytics/nationalhealth/county`,
      {
        factorcd: factorCd ?? null,
        dataSetId: dataSetId,
      }
    );
  }

  getMentalCountyPeerRanking(payload: any) {
    return this.http.post(
      `${environment.apiUrl}analytics/statementalhealth/peeranking`,

      payload
    );
  }

  getSubFactorDetails(payload: any) {
    return this.http.post(`${environment.apiUrl}common/subfactor`, payload);
  }

  getGrantsKeywords(factorCd: any) {
    return new Promise((resolve, reject) => {
      this.loader.showLoader();
      this.getGrantsKeywordsByCD(factorCd).subscribe({
        next: (response: any) => {
          resolve(response);
        },
        error: (error: any) => {
          resolve([]);
          console.error(error);
        },
        complete: () => {
          this.loader.hideLoader();
          // console.log('Request completed');
        },
      });
    });
  }
  private getGrantsKeywordsByCD(factorCd: any) {
    return this.http.get(`${environment.apiUrl}grant/keywords/${factorCd}`);
  }

  getStrategiesForSelectedHF(factorTypeId: any, payload: any) {
    return this.http.post(
      `${environment.apiUrl}strategy/strategies/${factorTypeId}`,
      payload
    );
  }

  getStrategiesForHf(payload: any) {
    return this.http.post(`${environment.apiUrl}strategy/strategies`, payload);
  }

  getNetworkProviders(payload: any) {
    return this.http.post(
      `${environment.apiUrl}common/network-providers`,
      payload
    );
  }

  getCountiesForHf(payload: any) {
    return this.http.post(`${environment.apiUrl}county/factorsummary`, payload);
  }

  getSVIFactors() {
    return this.http.post(`${environment.apiUrl}common/svi-factors`, undefined);
  }

  getInstructionsSummary() {
    return this.http.post(
      `${environment.apiUrl}common/instructions`,
      undefined
    );
  }

  getCustomSections(grantId: any, stateCode: any) {
    return this.http.post(
      `${environment.apiUrl}functions/narrative-documentsections${
        grantId != null ? '/' + grantId : ''
      }${stateCode != null ? '/' + stateCode : ''}`,
      null
    );
  }

  getStrategiesForSelectedHFs(payload: any) {
    return new Promise((resolve, reject) => {
      this.getStrategiesForHf(payload).subscribe({
        next: (response: any) => {
          resolve(response ?? []);
        },
        error: (error: any) => {
          resolve(null);
          console.error(error);
        },
        complete: () => {},
      });
    });
  }

  getEvidencesRatings() {
    return this.http.get(`${environment.apiUrl}strategy/evidence`);
  }

  openModal() {
    if (this.matLoginDialogRef) {
      try {
        this.matLoginDialogRef.close();
      } catch (error) {}
    }
    if (this.maAgreementDialogRef) {
      try {
        this.maAgreementDialogRef.close();
      } catch (error) {}
    }
    this.matLoginDialogRef = this.dialog.open(LoginDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      backdropClass: 'backdropBackground',
    });
    this.matLoginDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response && !this.auth.hasAgreedTerns) {
        this.openUserAgreementDialog();
      }
    });
  }

  openUserAgreementDialog() {
    // if (this.maAgreementDialogRef) {
    //   try {
    //     this.maAgreementDialogRef.close();
    //   } catch (error) {}
    // }
    // this.maAgreementDialogRef = this.dialog.open(UserAgreementDialogComponent, {
    //   hasBackdrop: true,
    //   disableClose: true,
    //   autoFocus: false,
    //   backdropClass: 'backdropBackground',
    //   width: '80vw',
    // });
    // this.maAgreementDialogRef.afterClosed().subscribe((result: any) => {
    //   if (result) {
    //     this.auth.setHasAgreedTerns(true);
    //   }
    // });
  }

  openMap(para1: any, para2: any) {
    let dialogRef = this.dialog.open(D3ChoroplethMapComponent, {
      hasBackdrop: true,
      disableClose: false,
      autoFocus: false,
      panelClass: 'mapClass',
    });
    dialogRef.componentInstance.selectedCounty = para1;
    dialogRef.componentInstance.filterdata = para2;
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }

  getPeerCountyMapView(countycode: any, filterType: any) {
    return this.http.post(
      `${environment.apiUrl}county/peers/${countycode}/${filterType}`,
      ''
    );
  }

  getUsersettings() {
    return this.http.get(`${environment.apiUrl}usersettings`);
  }

  getStrategyList(payload: any) {
    return this.http.post(`${environment.apiUrl}strategy/list`, payload);
  }

  createStrategy(payload: any) {
    return this.http.post(`${environment.apiUrl}strategy/create`, payload);
  }

  getStrategyDetails(strategyId: any) {
    return this.http.get(`${environment.apiUrl}strategy/${strategyId}`);
  }

  updateStrategy(payload: any) {
    return this.http.put(`${environment.apiUrl}strategy/update`, payload);
  }

  deleteStrategy(strategyId: any) {
    return this.http.delete(`${environment.apiUrl}strategy/${strategyId}`);
  }

  updateUsersettings(payload: any) {
    return this.http.put(`${environment.apiUrl}usersettings`, payload);
  }

  saveUsersettings(payload: any) {
    return this.http.post(`${environment.apiUrl}usersettings`, payload);
  }

  getGrants(fispcd: any, factorCd: any, payload: any) {
    return this.http.post(
      `${environment.apiUrl}grant/Grants/${fispcd}/${factorCd}`,
      payload
    );
  }

  getGrantsDashboard(fispcd: any, payload: any) {
    // return this.http.get(`${environment.apiUrl}grant/Grants/${fispcd}`);
    return this.http.post(`${environment.apiUrl}grant/Grants`, payload);
  }

  getGrantDetails(id: any, stateCode: any) {
    return this.http.get(
      `${environment.apiUrl}grant/Grants/${id}/${stateCode}`
    );
  }

  downloadRFADocument() {
    return this.http.get(`${environment.apiUrl}asdasdad`);
  }

  acceptTerms() {
    return this.http.get(`${environment.apiUrl}common/terms`);
  }

  openAddSavedSearch(stateData: any, dataForEdit: any, fromexplore?: any) {
    let dialogRef = this.dialog.open(SavedSearchAddComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      backdropClass: 'backdropBackground',
      width: '50vw',
      data: {
        displayOrder: this.auth.getDisplayOrder(),
        dataForEdit: dataForEdit,
        isUpdating: dataForEdit ? true : false,
        fromExplore: fromexplore,
      },
    });
    dialogRef.componentInstance.stateData = stateData;
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.success) {
          this.addOrEditSavedSearch = true;
          setTimeout(() => {
            this.addOrEditSavedSearch = false;
          }, 3000);
          this.addeditSaved.next('true');
          if (result.fromExplore) {
            this.userSettingsUpdated.next('true');
          }
        }
      }
    });
  }

  getDisparityStateIndicator(stateCode: any) {
    return this.http.get(
      `${environment.apiUrl}disparity/stateindicator?stateCode=${stateCode}`
    );
  }
  getDisparityGeographicIndicator(fipsCD: any) {
    return this.http.get(
      `${environment.apiUrl}disparity/countyindicator?fipsCD=${fipsCD}`
    );
  }
  getStateDemographicDisparity(factorcd: any, stateabbreviation: any) {
    return this.http.get(
      `${environment.apiUrl}disparity/state-demographic/${factorcd}/${stateabbreviation}`
    );
  }
  getStateDisparityPromise(
    factorcd: any,
    stateabbreviation: any,
    showProgress: boolean = false
  ) {
    if (this.stateDisparity) {
      try {
        this.stateDisparity.unsubscribe();
      } catch (error) {}
    }
    return new Promise((resolve, reject) => {
      if (showProgress) {
        this.loader.showLoader();
      }
      this.stateDisparity = this.getStateDemographicDisparity(
        factorcd,
        stateabbreviation
      ).subscribe({
        next: (response: any) => {
          resolve(response);
        },
        error: (error: any) => {
          resolve([]);
          console.error(error);
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
    });
  }
  getCountyDemographicDisparity(factorcd: any, stateabbreviation: any) {
    return this.http.get(
      `${environment.apiUrl}disparity/county-demographic/${factorcd}/${stateabbreviation}`
    );
  }
  getStateGeographicDisparity(factorcd: any, stateabbreviation: any) {
    return this.http.get(
      `${environment.apiUrl}disparity/state-geographic/${factorcd}/${stateabbreviation}`
    );
  }
  getDisparityZScores(factorcd: any, stateabbreviation: any) {
    return this.http.get(
      `${environment.apiUrl}disparity/countylevelzscore/${factorcd}/${stateabbreviation}`
    );
  }

  getGrantsFilterData() {
    return this.http.get(`${environment.apiUrl}grant/Grants/DashboardFilter`);
  }

  triggerContentGeneration(payload: any) {
    return this.http.post(
      `${environment.apiUrl}functions/generate-narrativedoc`,
      payload
    );
  }

  /**
   * Support Module Apis
   */

  getSupportRequests(payload: any) {
    return this.http.post(`${environment.apiUrl}Support/items/all`, payload);
  }

  getSupportRequestDetails(id: any) {
    return this.http.get(`${environment.apiUrl}Support/${id}`);
  }

  createSupportRequest(
    payload: any,
    fileUploads: any,
    isUpdate: boolean,
    updateStatus: boolean = true
  ) {
    let request = cloneDeep(payload);
    const formData: FormData = new FormData();
    for (var i = 0; i < fileUploads.length; i++) {
      formData.append('files', fileUploads[i]);
    }
    if (isUpdate) {
      request.fileUploads = request.fileUploads?.map((it: any) => {
        return {
          isDeleted: it.isDeleted,
          uploadId: it.uploadId,
          recordId: it.recordId,
          downloadUrl: '',
          fileId: '',
          fileName: '',
        };
      });

      formData.append('data', JSON.stringify(request));
      return this.http.put(`${environment.apiUrl}Support`, formData);
    } else {
      delete request.fileUploads;
      formData.append('data', JSON.stringify(request));
      return this.http.post(`${environment.apiUrl}Support/create`, formData);
    }
  }

  /**
   * Support Module Apis END
   */

  checkNarrativeStatues(statusQueryUrl: string) {
    return this.http.get(statusQueryUrl);
  }

  downloadFactSheet(payload: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.post(
      `${environment.apiUrl}pdfgenerator/pdfbygrant`,
      payload,
      {
        headers: headers,
        responseType: 'arraybuffer',
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getFactors() {
    return this.http.get(`${environment.apiUrl}common/factors`);
  }

  getDepartments(stateCode: any) {
    return this.http.get(
      `${environment.apiUrl}common/departments/${stateCode}`
    );
  }

  getTenantDetails() {
    return this.http.get(`${environment.apiUrl}${Constants.TENANT_API}`);
  }

  openGrantsWebPage(factorCD: any) {
    let confirmation = sessionStorage.getItem(
      Constants.GRANTS_NAVIGATION_CONFIRMATION
    );
    if (confirmation && Number(confirmation) == 1) {
      this.openWebPage(factorCD);
      return;
    }

    let dialog = this.dialog.open(AlertpopupComponent, {
      hasBackdrop: true,
      disableClose: false,
      autoFocus: false,
      backdropClass: 'backdropBackground',
      data: {
        title: 'Do you want to continue?',
        postitionButtonMessage: 'Continue',
        // secondaryButtonMessage: "Yes, Don't ask again",
        negativeButtonMessage: 'Stay in EY Impact',
        isInnerHTML: true,
        shortUrl: 'www.grants.gov',
      },
    });

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.dontAskAgain) {
          sessionStorage.setItem(
            Constants.GRANTS_NAVIGATION_CONFIRMATION,
            String(1)
          );
        }
        this.openWebPage(factorCD);
      }
    });
  }
  async openWebPage(factorCD: any) {
    let keywords: any = await this.getGrantsKeywords(Number(factorCD));
    if (!keywords) {
      keywords = '';
    } else {
      keywords = keywords?.keywords;
    }
    let strategyUrl = `https://www.grants.gov/custom/search.jsp#jp=shvsp&cp=1&facetSelectedDetailsMap={"fundingInstruments":{"G":"Grant"},"eligibilities":{"02":"City or township governments","01":"County governments","00":"State governments"}}&searchParams={"fundingInstruments":"G","eligibilities":"02|01|00","startRecordNum":0,"keyword":"${keywords}","oppNum":"","cfda":"","oppStatuses":"forecasted|posted","sortBy":"openDate|desc"}`;

    window.open(strategyUrl, '_blank');
  }

  openStrategyWebPage(strategyUrl: string) {
    let confirmation = sessionStorage.getItem(
      Constants.STRATEGY_NAVIGATION_CONFIRMATION
    );
    if (confirmation && Number(confirmation) == 1) {
      window.open(strategyUrl, '_blank');
      return;
    }
    let dialog = this.dialog.open(AlertpopupComponent, {
      hasBackdrop: true,
      disableClose: false,
      autoFocus: false,
      backdropClass: 'backdropBackground',
      data: {
        title: 'Do you want to continue?',
        postitionButtonMessage: 'Continue',
        // secondaryButtonMessage: "Yes, Don't ask again",
        negativeButtonMessage: 'Stay in EY Impact',
        isInnerHTML: true,
        url: strategyUrl,
        shortUrl: 'www.countyhealthrankings.org',
      },
    });

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.dontAskAgain) {
          sessionStorage.setItem(
            Constants.STRATEGY_NAVIGATION_CONFIRMATION,
            String(1)
          );
        }
        window.open(strategyUrl, '_blank');
      }
    });
  }

  openNoFoWebPage(nofoUrl: string, shortUrl: string) {
    let confirmation = localStorage.getItem(
      Constants.NOFO_NAVIGATION_CONFIRMATION
    );
    if (confirmation && Number(confirmation) == 1) {
      window.open(nofoUrl, '_blank');
      return;
    }
    let dialog = this.dialog.open(AlertpopupComponent, {
      hasBackdrop: true,
      disableClose: false,
      autoFocus: false,
      backdropClass: 'backdropBackground',
      data: {
        title: 'Do you want to continue?',
        postitionButtonMessage: 'Continue',
        // secondaryButtonMessage: "Yes, Don't ask again",
        negativeButtonMessage: 'Stay in EY Impact',
        isInnerHTML: true,
        url: nofoUrl,
        shortUrl: shortUrl.startsWith('www.') ? shortUrl : `www.${shortUrl}`,
      },
    });

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.dontAskAgain) {
          localStorage.setItem(
            Constants.NOFO_NAVIGATION_CONFIRMATION,
            String(1)
          );
        }
        window.open(nofoUrl, '_blank');
      }
    });
  }

  normalizeAmountRange(minGrantAmount: number, maxGrantAmount: number): string {
    let grantAmount = 'N/A';
    if (
      (minGrantAmount || minGrantAmount == 0) &&
      (maxGrantAmount || maxGrantAmount == 0)
    ) {
      let min =
        minGrantAmount != 0
          ? minGrantAmount.toLocaleString('en-US', {
              minimumIntegerDigits: 1,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          : '0';
      let max =
        maxGrantAmount != 0
          ? maxGrantAmount.toLocaleString('en-US', {
              minimumIntegerDigits: 1,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          : '0';
      grantAmount = `$${min} - $${max}`;
    } else if (!minGrantAmount && maxGrantAmount) {
      grantAmount = `$${maxGrantAmount.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`;
    } else if (minGrantAmount && !maxGrantAmount) {
      grantAmount = `$${minGrantAmount.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`;
    } else {
    }
    return grantAmount;
  }

  // Function to fix decimal point with decimalPrecision value
  fixDecimal(input: any, decimalPrecision: number = 2) {
    if (!input) return '';
    // Convert input to number if it's a string
    let number = typeof input === 'string' ? Number(input) : input;
    //if (!Number.isInteger(number)) {
    number = number.toFixed(decimalPrecision);
    var str = number.toString().split('.');
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return str.join('.');
    // } else {
    //   return number.toString();
    // }
  }

  fixDecimalForNational(input: any, decimalPrecision: number = 2) {
    if (input == null || input == undefined) return '';
    if (input == -9999) return 'N/A';
    if (input == 0) return '0';
    // Convert input to number if it's a string
    let number = typeof input === 'string' ? Number(input) : input;
    //if (!Number.isInteger(number)) {
    number = number.toFixed(decimalPrecision);
    var str = number.toString().split('.');
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return str.join('.');
    // } else {
    //   return number.toString();
    // }
  }

  unitType(
    input: any,
    unitType: any,
    unitSymbol: any,
    propotion: any,
    isDemographicDisparity: boolean,
    fromOutcomDetailView?: boolean
  ) {
    let unitFactorValue: any;
    if (input == null && isDemographicDisparity) {
      return 'NR';
    } else {
      if (unitType) {
        switch (unitType) {
          case 'Percentage':
            unitFactorValue = input + unitSymbol;
            return unitFactorValue;
          case 'Integer':
            unitFactorValue = input + unitSymbol;
            return unitFactorValue;
          case 'Ratio':
            unitFactorValue = input + unitSymbol + propotion;
            // if (fromOutcomDetailView) {
            //   unitFactorValue = propotion + unitSymbol + input;
            // }
            return unitFactorValue;
          default:
            unitFactorValue = input + unitSymbol;
            return unitFactorValue;
        }
      } else {
        return input;
      }
    }
  }

  openGrantPopup(grantData: any, type: any) {
    let dialogRef = this.dialog.open(GrantPopupComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      backdropClass: 'backdropBackground',
      width: '50vw',
    });
    dialogRef.componentInstance.grantData = grantData;
    dialogRef.componentInstance.isMarkAsApplied = type;
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }

  getFormattedGrantAmount(estimatedFunding: any): string {
    if (estimatedFunding) {
      if (String(estimatedFunding).includes('-')) {
        let funds = String(estimatedFunding).split('-');
        return funds
          .map((it: string) => {
            if (it.includes(',')) {
              return `$${it}`;
            }
            return `$${formatNumber(Number(it), 'en-US', '3.0-0')}`;
          })
          .join('-');
      } else if (String(estimatedFunding).includes(',')) {
        return `$${estimatedFunding}`;
      } else {
        return `$${formatNumber(estimatedFunding, 'en-US', '3.0-0')}`;
      }
    }
    return 'N/A';
  }

  generateUniqueID(idLength: number = 15) {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}:"<>?';
    let id = '';
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      id += chars[randomIndex];
    }
    return id;
  }

  getUniqueId(parts: number = 6): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      const S4 = (((1 + Math.random()) * 0x10000) | 0)
        .toString(16)
        .substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  getApplicationDataSets() {
    return this.http.get(`${environment.apiUrl}${Constants.DATA_SET_API}`);
  }

  getFactorSubTypes() {
    return this.http.post(`${environment.apiUrl}common/factor-filters`, {
      datasetId: null,
      focusArea: '',
    });
  }

  mergeArraysAndRemoveDuplicates(
    arr1: any[],
    arr2: any[],
    property: string
  ): any[] {
    const mergedArray = [...arr2, ...arr1];
    const uniqueItemsMap = new Map();

    for (const item of mergedArray) {
      const propertyValue = item[property];
      if (!uniqueItemsMap.has(propertyValue)) {
        uniqueItemsMap.set(propertyValue, item);
      }
    }
    const uniqueArray = Array.from(uniqueItemsMap.values());

    return uniqueArray;
  }

  generateSections(min: number, max: number, numSections: number = 6): any[] {
    const sectionSize = (max - min) / (numSections - 1); // We need (numSections - 1) intervals for numSections midpoints
    const midpoints: number[] = [];

    for (let i = 0; i < numSections - 1; i++) {
      const midpoint = min + i * sectionSize;
      midpoints.push(midpoint);
    }

    // Ensure that the last section's midpoint is the upper bound
    midpoints.push(max);

    return midpoints;
  }

  splitRange(
    min: number,
    max: number
  ): { first: number; second: number; third: number } {
    const range = max - min;

    if (range < 2) {
      return {
        first: min,
        second: min + range / 3,
        third: max,
      };
    } else {
      return {
        first: min,
        second: min + range / 3,
        third: min + (2 * range) / 3,
      };
    }
  }

  getBarColor(value: any) {
    if (value <= -1.28)
      return { color: '#00c544', performance: 'Well Above Average' };
    else if (value > -1.28 && value <= -0.67)
      return { color: '#8edb00', performance: 'Above Average' };
    else if (value > -0.67 && value <= 0.67)
      return { color: '#eed208', performance: 'Average' };
    else if (value > 0.67 && value < 1.28)
      return { color: '#f38200', performance: 'Below Average' };
    else return { color: '#db4d00', performance: 'Well Below Average' };
  }

  getBarColorUsingRange(range: any[] = [], value: number) {
    if (!range || range.length < 5)
      return { color: '#FFFFFF', performance: 'N/A' };

    if (value <= range[1])
      return { color: '#00c544', performance: 'Well Above Average' };
    else if (value > range[1] && value <= range[2])
      return { color: '#8edb00', performance: 'Above Average' };
    else if (value > range[2] && value <= range[3])
      return { color: '#eed208', performance: 'Average' };
    else if (value > range[3] && value <= range[4])
      return { color: '#f38200', performance: 'Below Average' };
    else return { color: '#db4d00', performance: 'Well Below Average' };
  }

  sendInsights(event: any, type: any, message: any) {
    try {
      switch (message) {
        case InsightCustomEvents.User_IN:
          this.insights.startTracker(event);
          break;
        case InsightCustomEvents.User_OUT:
          this.insights.stopTracker(event, type);
          break;
        case InsightCustomEvents.Unauthorized:
        case InsightCustomEvents.OtheEvent:
          this.insights.trackEvent(event, type);
          break;
        default:
          break;
      }
    } catch (error) {}
  }

  getShortUrl(url: string) {
    if (!url?.length) {
      return '';
    }

    // Remove 'http://' or 'https://' from the beginning if present
    url = url.replace(/^(https?:\/\/)?/, '');

    // Remove 'www.' if present
    url = url.replace(/^www\./, '');

    // Extract the base URL
    const matches = url.match(/^[^\/]+/);
    if (matches && matches.length > 0) {
      return matches[0];
    } else {
      return url; // Return the original URL if no match is found
    }
  }

  processHeirarchyResponse(response: any, entityName: string): any {
    const groupedMap: any = {};
    response?.forEach((item: any) => {
      if (!groupedMap[item[entityName]]) {
        groupedMap[item[entityName]] = [];
      }
      groupedMap[item[entityName]].push({ ...item });
    });

    const result: any = Object.keys(groupedMap)
      .map((key) => ({
        entityName: key == 'null' ? null : key,
        panelOpen: false,
        counties: groupedMap[key],
      }))
      ?.sort((a: any, b: any) => {
        return a.entityName < b.entityName ? -1 : 1;
      });
    return result;
  }

  getRichTextContent(content: any): Promise<string> {
    return marked(content, {
      ...marked.getDefaults(),
      async: true,
      breaks: true,
    });
  }

  getSanitizedHtml(htmlString: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }
}
