import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderFlagChange = new BehaviorSubject<boolean>(false);

  public obrvableLoaderFlag;

  constructor() {
    this.obrvableLoaderFlag = this.loaderFlagChange.asObservable();
  }

  public showLoader(): void {
    this.loaderFlagChange.next(true);
  }

  public hideLoader(): void {
    this.loaderFlagChange.next(false);
  }
}
