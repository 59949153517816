<app-header [hideHeaderItems]="isLoginCallBack()"></app-header>
<div
  class="center-content"
  [@routeAnimations]="getRouteAnimationData()"
  (scroll)="onPageScroll($event)"
>
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<app-loader></app-loader>
