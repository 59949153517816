import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-grant-popup',
  templateUrl: './grant-popup.component.html',
  styleUrls: ['./grant-popup.component.scss'],
})
export class GrantPopupComponent {
  @Input() grantData: any;
  grantAppNum: any;
  loading = false;
  @Input() isMarkAsApplied = true;
  selectedStatus: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GrantPopupComponent>
  ) {}

  close() {
    this.dialogRef.close();
  }
  onStatusChange(event: any) {}
}
