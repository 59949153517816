<div class="wrapper">
  <div class="pt-4 HeaderClass pb-3">{{ title }}</div>
  <div class="login-btn-container mt-3">
    <span *ngIf="loading" class="spinner-border spinner-border-lg ms-1"></span>
  </div>
</div>
<div *ngIf="submitted && errorMsg && errorMsg.length > 0" class="error-wrapper">
  <div
    class="error-msg-container p-3"
    [ngClass]="{
      'success-msg-container': isSuccess
    }"
  >
    {{ errorMsg }}
  </div>
</div>
