import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { HealthAppService } from 'src/app/services/explore-health/health-app.service';

@Component({
  selector: 'app-saved-search-add',
  templateUrl: './saved-search-add.component.html',
  styleUrls: ['./saved-search-add.component.scss'],
})
export class SavedSearchAddComponent implements OnInit {
  countyCtrl = new FormControl('');
  stateCtrl = new FormControl('');
  counties: any = [];
  filteredStates: any = [];
  filteredCounty: any = [];
  @Input() stateData: any;
  countyBasedInput: any = false;
  stateSelected: any;
  countySelected: any;
  selectedDataset: any = 'cdc';
  selectedPopAdj: any = false;
  selectedWghtedScores: any = false;
  loading = false;
  searchName: any;
  error = {
    stateError: false,
    countyError: false,
    searchNameError: false,
  };
  isDisparity: any = false;
  selectedDisparityView = 'Demographic Disparity';

  constructor(
    public haService: HealthAppService,
    private snack: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SavedSearchAddComponent>
  ) {}

  ngOnInit() {
    if (this.data.dataForEdit) {
      if (this.data.dataForEdit.stateSettings) {
        this.countyBasedInput = false;
        this.isDisparity = false;
        this.setStateData(
          this.data.dataForEdit.stateSettings,
          this.data.dataForEdit.settingsName
        );
      } else if (this.data.dataForEdit.countySettings) {
        this.countyBasedInput = true;
        this.isDisparity = false;
        this.setCountyData(
          this.data.dataForEdit.countySettings,
          this.data.dataForEdit.settingsName
        );
      } else if (this.data.dataForEdit.disparitySettings) {
        this.isDisparity = true;
        if (
          this.data.dataForEdit.disparitySettings.view == 'Geographic Disparity'
        ) {
          this.countyBasedInput = false;
        } else {
          this.countyBasedInput = true;
        }
        this.setDisparityData(
          this.data.dataForEdit.disparitySettings,
          this.data.dataForEdit.settingsName
        );
      }
      this.formSettingsName(true);
    }
    if (this.data.fromExplore) {
      this.countyBasedInput = this.data.fromExplore.isCounty;
    }
    this.filteredStates = this.stateCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterStates(value || ''))
    );
    this.filteredCounty = this.countyCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterCounty(value || ''))
    );
  }

  setStateData(data: any, name: any) {
    let elem = this.stateData?.filter((option: any) => {
      return (
        option.stateName == data.stateName && option.stateCode == data.stateCode
      );
    });

    if (elem && elem.length > 0) {
      this.stateSelected = elem[0];
    }
    this.selectedPopAdj = data.populationAdjusted;
    this.selectedWghtedScores = data.weightedScore;
    this.selectedDataset = data.peerGroup;
    this.searchName = name;
  }

  setCountyData(data: any, name: any) {
    let elem = this.stateData?.filter((option: any) => {
      return (
        option.stateName == data.stateName && option.stateCode == data.stateCode
      );
    });

    if (elem && elem.length > 0) {
      this.stateSelected = elem[0];
    }
    if (this.stateSelected) {
      this.setCountyForDropdown(data);
    }

    this.selectedWghtedScores = data.weightedScore;
    this.selectedDataset = data.peerGroup;
    this.searchName = name;
  }

  setDisparityData(data: any, name: any) {
    let elem = this.stateData?.filter((option: any) => {
      return (
        option.stateName == data.stateName && option.stateCode == data.stateCode
      );
    });

    if (elem && elem.length > 0) {
      this.stateSelected = elem[0];
    }
    if (this.stateSelected && data.countyCode) {
      this.setCountyForDropdown(data);
    }

    this.selectedDisparityView = data.view;
    this.searchName = name;
  }

  filterStates(name: string) {
    if (this.stateData && this.stateData.length > 0) {
      const filterValue = name.toLowerCase();
      return this.stateData?.filter((option: any) =>
        option.stateName.toLowerCase().startsWith(filterValue)
      );
    }
    return [];
  }

  filterCounty(name: string) {
    if (this.counties && this.counties.length > 0) {
      const filterValue = name.toLowerCase();
      return this.counties?.filter((option: any) =>
        option.countyName.toLowerCase().startsWith(filterValue)
      );
    }
    return [];
  }

  stateValueChanged(event: any) {
    this.stateSelected = null;
    this.error.stateError = false;
    this.counties = [];
    // this.filteredCounty = [];
    if (event.source.value?.length > 0) {
      this.stateSelected = this.stateData?.find(
        (state: any) => state.stateName == event.source.value
      );
      if (this.stateSelected) {
        this.setCountyForDropdown();
        if (
          this.stateSelected.stateCode == 'MBPROXY' &&
          this.selectedDataset == 'national'
        ) {
          this.selectedDataset = 'cdc';
        }
      }
      //this.haService.selectedState = this.stateSelected;
      this.stateCtrl.patchValue('');
    }
    this.formSettingsName();
  }

  async setCountyForDropdown(fromEdit?: any) {
    this.counties = await this.haService.getCountiesByStateName(
      this.stateSelected.stateName,
      false
    );
    this.filteredCounty = this.countyCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterCounty(value || ''))
    );
    if (this.counties && fromEdit) {
      let elem = this.counties?.filter((option: any) => {
        return (
          option.countyName == fromEdit.countyName &&
          option.countyCode == fromEdit.countyCode
        );
      });

      if (elem && elem.length > 0) {
        this.countySelected = elem[0];
      }
    }
  }

  countyValueChanged(event: any) {
    this.countySelected = null;
    this.error.countyError = false;
    if (event.source.value?.length > 0) {
      this.countySelected = this.counties?.find(
        (county: any) => county.countyName == event.source.value
      );
      this.countyCtrl.patchValue('');
    }
    this.formSettingsName();
  }

  onPeerGroupChange(event: any) {
    this.formSettingsName();
  }

  formSettingsName(fromEdit?: any) {
    // if (!this.data.isUpdating) {
    let searchName = '';
    if (this.isDisparity) {
      searchName = searchName + 'Disparity_';
    }
    if (this.stateSelected?.stateName) {
      searchName = searchName + `${this.stateSelected?.stateName}`;
    }
    if (this.countyBasedInput && this.countySelected?.countyName) {
      searchName = searchName + `_${this.countySelected?.countyName}`;
    }
    if (fromEdit && this.countyBasedInput) {
      searchName =
        searchName +
        (!this.isDisparity
          ? `_${this.data.dataForEdit.countySettings.countyName}`
          : `_${this.data.dataForEdit.disparitySettings.countyName}`);
    }
    if (this.selectedDataset && !this.isDisparity) {
      searchName = searchName + `_${this.selectedDataset?.toLocaleUpperCase()}`;
      if (!this.countyBasedInput) {
        searchName =
          searchName + `_${this.selectedPopAdj ? 'Adjusted' : 'NonAdjusted'}`;
      }
      searchName =
        searchName +
        `_${this.selectedWghtedScores ? 'Weighted' : 'NonWeighted'}`;
    }
    if (this.isDisparity && this.selectedDisparityView) {
      searchName =
        searchName + `_${this.selectedDisparityView?.toLocaleUpperCase()}`;
    }
    this.searchName = searchName;
    return this.searchName;
  }

  fnPopAdjChange(event: any) {
    this.selectedPopAdj = event.target.checked;
    this.formSettingsName();
  }

  fnWeightedScoreChange(event: any) {
    this.selectedWghtedScores = event.target.checked;
    this.formSettingsName();
  }

  saveFn() {
    let payload: any = {
      disparitySettings: null,
      stateSettings: null,
      countySettings: null,
      isActive: true,
      displayOrder: this.data.displayOrder ?? 1,
      settingsName: null,
    };
    if (this.checkError()) {
      if (!this.data.isUpdating || this.data.fromExplore) {
        if (this.countyBasedInput && !this.isDisparity) {
          payload.countySettings = {};
          payload.countySettings.stateName = this.stateSelected.stateName;
          payload.countySettings.stateCode = this.stateSelected.stateCode;
          payload.countySettings.countyName = this.countySelected.countyName;
          payload.countySettings.countyCode = this.countySelected.countyCode;
          payload.countySettings.peerGroup = this.selectedDataset;
          payload.countySettings.weightedScore = this.selectedWghtedScores;
          payload.settingsName = this.searchName;
        } else if (!this.countyBasedInput && !this.isDisparity) {
          payload.stateSettings = {};
          payload.stateSettings.stateName = this.stateSelected.stateName;
          payload.stateSettings.stateCode = this.stateSelected.stateCode;
          payload.stateSettings.peerGroup = this.selectedDataset;
          payload.stateSettings.populationAdjusted = this.selectedPopAdj;
          payload.stateSettings.weightedScore = this.selectedWghtedScores;
          payload.settingsName = this.searchName;
        } else if (this.isDisparity && !this.countyBasedInput) {
          payload.disparitySettings = {};
          payload.disparitySettings.stateName = this.stateSelected.stateName;
          payload.disparitySettings.stateCode = this.stateSelected.stateCode;
          payload.disparitySettings.view = this.selectedDisparityView;
          payload.settingsName = this.searchName;
        } else if (this.isDisparity && this.countyBasedInput) {
          payload.disparitySettings = {};
          payload.disparitySettings.stateName = this.stateSelected.stateName;
          payload.disparitySettings.stateCode = this.stateSelected.stateCode;
          payload.disparitySettings.countyName = this.countySelected.countyName;
          payload.disparitySettings.countyCode = this.countySelected.countyCode;
          payload.disparitySettings.view = this.selectedDisparityView;
          payload.settingsName = this.searchName;
        }

        this.saveNewData(payload);
      } else {
        if (this.data.dataForEdit.stateSettings) {
          this.data.dataForEdit.stateSettings.stateCode =
            this.stateSelected.stateCode;
          this.data.dataForEdit.stateSettings.stateName =
            this.stateSelected.stateName;
          this.data.dataForEdit.stateSettings.populationAdjusted =
            this.selectedPopAdj;
          this.data.dataForEdit.stateSettings.peerGroup = this.selectedDataset;
          this.data.dataForEdit.stateSettings.weightedScore =
            this.selectedWghtedScores;
          this.data.dataForEdit.settingsName = this.searchName;
          this.data.dataForEdit.countySettings = null;
        } else if (this.data.dataForEdit.countySettings) {
          this.data.dataForEdit.countySettings.stateCode =
            this.stateSelected.stateCode;
          this.data.dataForEdit.countySettings.stateName =
            this.stateSelected.stateName;
          this.data.dataForEdit.countySettings.countyCode =
            this.countySelected.countyCode;
          this.data.dataForEdit.countySettings.peerGroup = this.selectedDataset;
          this.data.dataForEdit.countySettings.countyName =
            this.countySelected.countyName;
          this.data.dataForEdit.countySettings.weightedScore =
            this.selectedWghtedScores;
          this.data.dataForEdit.settingsName = this.searchName;
          this.data.dataForEdit.stateSettings = null;
        } else if (this.data.dataForEdit.disparitySettings) {
          this.data.dataForEdit.disparitySettings = {
            stateCode: this.stateSelected.stateCode,
            stateName: this.stateSelected.stateName,
            view: this.selectedDisparityView,
          };
          this.data.dataForEdit.settingsName = this.searchName;
          if (this.countyBasedInput) {
            this.data.dataForEdit.disparitySettings.countyCode =
              this.countySelected.countyCode;
            this.data.dataForEdit.disparitySettings.countyName =
              this.countySelected.countyName;
          }
          this.data.dataForEdit.stateSettings = null;
          this.data.dataForEdit.countySettings = null;
        }
        payload.stateSettings = this.data.dataForEdit.stateSettings;
        payload.countySettings = this.data.dataForEdit.countySettings;
        payload.settingsName = this.data.dataForEdit.settingsName;
        payload.disparitySettings = this.data.dataForEdit.disparitySettings;
        this.editData(this.data.dataForEdit);
      }
    }

    // console.log(payload);
  }

  saveNewData(payload: any) {
    this.loading = true;
    if (this.data.fromExplore) {
      this.haService.showProgressSpinner = true;
    }
    this.haService.saveUsersettings(payload).subscribe({
      next: (response: any) => {
        this.showCallBackResponse(true, payload);
      },
      error: (error: any) => {
        this.showCallBackResponse(false);
      },
      complete: () => {},
    });
  }

  showCallBackResponse(isSuccess: boolean, payload?: any) {
    this.loading = false;
    if (!isSuccess) {
      this.snack.openErrorBar('Failed to save settings');
    } else {
      if (payload) {
        this.dialogRef.close({
          success: true,
          fromExplore: this.data.fromExplore,
        });
        if (this.data.fromExplore) {
          this.snack.openSuccessBar('Added to saved search');
        }
      }
    }
  }

  editData(payload: any) {
    this.loading = true;
    this.haService.updateUsersettings([payload]).subscribe({
      next: (response: any) => {
        this.showCallBackResponse(true, payload);
      },
      error: (error: any) => {
        this.showCallBackResponse(false);
      },
      complete: () => {},
    });
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  radioChange(type: any) {
    if (type == 'state') {
      this.countyBasedInput = false;
      this.isDisparity = false;
    } else if (type == 'county') {
      this.countyBasedInput = true;
      this.isDisparity = false;
    } else if (type == 'disparity') {
      this.isDisparity = true;
      if (this.selectedDisparityView == 'Demographic Disparity') {
        this.countyBasedInput = true;
      } else {
        this.countyBasedInput = false;
      }
    }
    this.formSettingsName();
  }

  checkError() {
    if (this.countyBasedInput) {
      if (!this.stateSelected || !this.countySelected || !this.searchName) {
        if (!this.stateSelected) {
          this.error.stateError = true;
        }
        if (!this.countySelected) {
          this.error.countyError = true;
        }
        if (!this.searchName) {
          this.error.searchNameError = true;
        }
        return false;
      } else {
        return true;
      }
    } else {
      if (!this.stateSelected || !this.searchName) {
        if (!this.stateSelected) {
          this.error.stateError = true;
        }
        if (!this.searchName) {
          this.error.searchNameError = true;
        }
        return false;
      } else {
        return true;
      }
    }
  }

  SearchnameFn() {
    if (this.searchName && this.searchName != '') {
      this.error.searchNameError = false;
    } else {
      this.error.searchNameError = true;
    }
  }

  onDisparityChange(event: any) {
    if (event == 'Geographic Disparity') {
      this.countyBasedInput = false;
    } else {
      this.countyBasedInput = true;
      this.setCountyForDropdown();
    }
    this.formSettingsName();
  }
}
