<div class="popClass p-4">
  <div class="d-flex justify-content-end"></div>
  <div class="titleClass">
    <h5 class="fw-bold" *ngIf="isMarkAsApplied">Update Status</h5>
    <h5 class="fw-bold" *ngIf="!isMarkAsApplied">Track Application</h5>
  </div>
  <div class="mt-4 opportunity-Class">
    <label class="ft-14 ft-regular">Opportunity Number</label>
    <div class="valueClass mt-2">{{ grantData.grantOpportunityNumber }}</div>
  </div>
  <div class="mt-4 grantTitle-Class">
    <label class="ft-14 ft-regular">Grant Title</label>
    <div class="valueClass mt-2">{{ grantData.grantTtile }}</div>
  </div>
  <div class="row">
    <div class="mt-4 pt-2 col-5" *ngIf="isMarkAsApplied">
      <span class="mt-4">
        <label
          class="filter-title verticalAlign filter-title-secondary-padding col-12 mb-2 grantApp-Class"
          ><span class="text-class">Status</span></label
        >
        <mat-form-field floatLabel="always" class="peerGroupWidth">
          <div class="align-arrow">
            <mat-select
              #viewSelect
              class="mater-selection"
              panelClass="testClass1"
              name="status"
              placeholder="Select Status"
              id="status"
              [(ngModel)]="selectedStatus"
              (ngModelChange)="onStatusChange($event)"
              [ngClass]="{
                'rotate-down-arrow-icon': viewSelect.panelOpen
              }"
            >
              <ng-scrollbar
                appearance="compact"
                [autoHeightDisabled]="true"
                [autoWidthDisabled]="true"
                class="scroll-container hgt-9rem"
              >
                <mat-option value="interested">Interested</mat-option>
                <mat-option value="inProgress">In Progress</mat-option>
                <mat-option value="applied">Applied</mat-option>
                <mat-option value="won">Won</mat-option>
                <mat-option value="lost">Lost</mat-option>
              </ng-scrollbar>
            </mat-select>
          </div>
        </mat-form-field>
      </span>
    </div>
    <div class="mt-4 col-5 grantApp-Class">
      <label *ngIf="isMarkAsApplied && selectedStatus" class="text-class mt-2"
        >GRANT APPLICATION # (Optional)</label
      >
      <label *ngIf="!isMarkAsApplied">Grant Application #</label>
      <div class="mt-2">
        <input
          *ngIf="isMarkAsApplied && selectedStatus"
          type="text"
          name="grantApp"
          id="grantApp"
          placeholder="ex. GRANT12345678"
          class="input-label input-grant"
          [(ngModel)]="grantAppNum"
        />
        <span class="valueClass" *ngIf="!isMarkAsApplied">GRANT12345678</span>
      </div>
    </div>
  </div>

  <div class="mt-4" *ngIf="!isMarkAsApplied">
    Clicking Track Application will redirect you to the Grant.gov website.
  </div>
  <div class="btn-grp mt-5">
    <button type="submit" class="cancel-button mb-3" (click)="close()">
      Cancel
    </button>
    <button type="submit" class="save-button mb-3 ms-3">
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      {{ isMarkAsApplied ? "Update Status" : "Track Application" }}
    </button>
  </div>
</div>
