import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  /* It takes three parameters 
    1.the message string 
    2.the action 
    3.the duration, alignment, etc. */

  openSuccessBar(message: string, action?: string, durationInSeconds?: number) {
    this.snackBar.open(message, action ?? 'OK', {
      duration: durationInSeconds ? durationInSeconds * 1000 : 6000,
      // panelClass: ['white-snackbar'],
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar-styling', 'green-snackbar'],
    });
  }

  openErrorBar(message: string, action?: string, durationInSeconds?: number) {
    this.snackBar.open(message, action ?? 'OK', {
      duration: durationInSeconds ? durationInSeconds * 1000 : 6000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar-styling', 'red-snackbar'],
    });
  }

  openInfoBar(message: string, action?: string, durationInSeconds?: number) {
    this.snackBar.open(message, action ?? 'OK', {
      duration: durationInSeconds ? durationInSeconds * 1000 : 6000,
      panelClass: ['snackbar-styling', 'orange-snackbar'],
      verticalPosition: 'top',
      horizontalPosition: 'center',
      politeness: 'polite',
      // panelClass: ['']
    });
  }
}
