<div class="wrapper">
  <div class="px-5 py-3">
    <div class="headerText pb-3 mb-2 mt-2">
      <span *ngIf="!dataInstance.isUpdating">Create a new support request</span>
      <span *ngIf="dataInstance.isUpdating">Edit support request</span>
    </div>

    <div class="row">
      <div class="col-6">
        <span class="mt-4">
          <label class="filter-title pop-adj resolve-label-flex col-12 mb-2"
            ><span class="headerClass">SELECT PRIORITY</span>
            <!-- <div class="tooltip-definition">
              Tooltip definition would go here
            </div> -->
          </label>

          <mat-form-field floatLabel="always" class="stateCountyWidth">
            <div class="align-arrow">
              <img
                class="icon-color new"
                [ngClass]="{
                  low: newRequesPayload.priority == 'Low',
                  medium: newRequesPayload.priority == 'Medium',
                  high: newRequesPayload.priority == 'High'
                }"
                src="../../../../assets/icons/svg/warning.svg"
                alt="priority"
                height="24"
                width="24"
              />
              <mat-select
                #prioritySelect
                panelClass="testClass1"
                class="mater-selection"
                name="state"
                id="state"
                placeholder="Priority"
                [(ngModel)]="newRequesPayload.priority"
                [ngClass]="{
                  'rotate-down-arrow-icon': prioritySelect.panelOpen
                }"
              >
                <ng-scrollbar
                  appearance="compact"
                  [autoHeightDisabled]="true"
                  [autoWidthDisabled]="true"
                  class="scroll-container hgt-104px"
                >
                  <mat-option value="Low"> Low </mat-option>
                  <mat-option value="Medium"> Medium </mat-option>
                  <mat-option value="High"> High </mat-option>
                </ng-scrollbar>
              </mat-select>
            </div>
          </mat-form-field>

          <!-- <p *ngIf="error.countyError" class="mb-2 ms-2 errorDropdownText">
            Please choose a valid county
          </p> -->
        </span>
      </div>
      <div
        class="col-6"
        [ngClass]="{
          'justify-content-center pl-4 d-flex': !isAdmin
        }"
        *ngIf="dataInstance.isUpdating"
      >
        <span>
          <label
            class="filter-title pop-adj resolve-label-flex col-12 mb-2"
            [ngClass]="{
              'mb-3': !isAdmin
            }"
            ><span class="headerClass">STATUS</span>
          </label>

          <label
            *ngIf="!isAdmin"
            class="filter-title pop-adj resolve-label-flex col-12"
            ><span
              class="headerClass upper"
              [ngClass]="{
                inprogress:
                  newRequesPayload.status == 'In Progress' ||
                  newRequesPayload.status == 'Closed',
                resolved: newRequesPayload.status == 'Resolved',
                deleted: newRequesPayload.status == 'Deleted'
              }"
              >{{ newRequesPayload.status }}</span
            >
          </label>
          <mat-form-field
            *ngIf="isAdmin"
            floatLabel="always"
            class="stateCountyWidth"
          >
            <div class="align-arrow">
              <mat-select
                #statusSelect
                panelClass="testClass1"
                class="mater-selection"
                name="state"
                id="state"
                placeholder="Status"
                [(ngModel)]="newRequesPayload.status"
                [ngClass]="{
                  'rotate-down-arrow-icon': statusSelect.panelOpen
                }"
              >
                <ng-scrollbar
                  appearance="compact"
                  [autoHeightDisabled]="true"
                  [autoWidthDisabled]="true"
                  class="scroll-container hgt-104px"
                >
                  <mat-option value="In Progress"
                    >Move to In Progress</mat-option
                  >
                  <mat-option value="Resolved"> Mark as Resolved </mat-option>
                  <mat-option value="Closed"> Close Request</mat-option>
                  <!-- <mat-option value="Deleted"> Delete Request</mat-option> -->
                </ng-scrollbar>
              </mat-select>
            </div>
          </mat-form-field>
        </span>
      </div>

      <div class="col-12">
        <span class="mt-0">
          <label class="filter-title pop-adj resolve-label-flex col-12 mb-2"
            ><span class="headerClass">REQUEST TITLE</span>
            <!-- <div class="tooltip-definition">
              Please provide the title here
            </div> -->
          </label>
          <input
            class="TextInput input-box"
            type="text"
            [(ngModel)]="newRequesPayload.subject"
            placeholder="Enter title for your request"
          />
          <!-- <p  class="errorClass mb-0 ms-2">
            Please enter a valid name
          </p> -->
        </span>
      </div>
      <div class="col-9">
        <span class="mt-0">
          <label
            class="filter-title pop-adj resolve-label-flex col-12 mb-2 pt-3"
            ><span class="headerClass">DESCRIPTION</span>
            <!-- <div class="tooltip-definition">
              Please provide the description here
            </div> -->
          </label>
          <textarea
            class="TextInput text-box pt-2"
            cols="5"
            placeholder="Enter brief description for your request"
            [(ngModel)]="newRequesPayload.description"
          ></textarea>
          <!-- <p  class="errorClass mb-0 ms-2">
            Please enter a valid name
          </p> -->
        </span>
      </div>
      <div class="col-3">
        <span class="mt-0">
          <label
            class="filter-title pop-adj resolve-label-flex col-12 mb-2 pt-3"
            ><span class="headerClass">ATTACHMENTS</span>
            <div class="tooltip-definition"></div>
          </label>
          <div class="select-files d-flex justify-content-center">
            <input
              class="file-selector"
              type="file"
              (change)="handleFileInput($event)"
              (click)="handleFileInputClick($event)"
              multiple="multiple"
              accept="{{ acceptFileTypes }}"
              #file
            />
            <span
              class="btn btn-outline-primary btn-sm align-self-center button-color-update background-white-color"
              (click)="file.click()"
            >
              Select Files
            </span>
          </div>
          <!-- <p  class="errorClass mb-0 ms-2">
            Please enter a valid name
          </p> -->
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="col me-1"
          *ngIf="
            dataInstance.isUpdating &&
            newRequesPayload.fileUploads &&
            newRequesPayload.fileUploads.length > 0
          "
        >
          <div class="p-2 attachment-container">
            <div class="d-flex justify-content-start align-items-center">
              <span class="border-bottom pt-1 pb-1 ft-bold ft-14">
                Uploaded Files</span
              >
            </div>
            <div class="m-2"></div>
            <div class="c-box">
              <div
                *ngFor="let item of newRequesPayload.fileUploads"
                class="d-flex justify-content-start align-items-center"
              >
                <img
                  class="icon-color"
                  src=" {{
                    item.isDeleted
                      ? '../../../../assets/icons/svg/garbage-delete.svg'
                      : '../../../../assets/icons/svg/garbage.svg'
                  }}"
                  alt="delete"
                  height="18"
                  width="18"
                  (click)="item.isDeleted = !item.isDeleted"
                /><span
                  class="attachment m-1 hyperlink"
                  (click)="openFile(item)"
                  [ngClass]="{
                    deleted: item.isDeleted
                  }"
                  >{{ item.fileName }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col" *ngIf="selectedFiles && selectedFiles.length > 0">
          <div class="p-2 attachment-container ms-1">
            <div class="d-flex justify-content-between align-items-center">
              <span class="border-bottom pt-1 pb-1 ft-bold ft-14"
                >Selected Files
              </span>
              <span
                class="button-color-update no-bo px-1"
                (click)="clearUploadedFiles()"
              >
                Clear
              </span>
            </div>
            <div class="m-2"></div>
            <div class="c-box">
              <div *ngFor="let item of selectedFiles">
                <span class="attachment m-1">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12"
        *ngIf="
          (newRequesPayload.resolution && dataInstance.isUpdating) ||
          (dataInstance.isUpdating && isAdmin)
        "
      >
        <span class="mt-0">
          <label
            class="filter-title pop-adj resolve-label-flex col-12 mb-2 pt-3"
            ><span class="headerClass">RESOLUTION NOTES</span>
            <!-- <div class="tooltip-definition">
              Please provide the description here
            </div> -->
          </label>

          <div
            *ngIf="!isAdmin"
            class="TextInput disabled user-box overflow-auto"
          >
            {{ newRequesPayload.resolution }}
          </div>
          <textarea
            *ngIf="isAdmin"
            class="TextInput pt-2"
            cols="5"
            [(ngModel)]="newRequesPayload.resolution"
            maxlength="500"
          ></textarea>
          <!-- <p  class="errorClass mb-0 ms-2">
            Please enter a valid name
          </p> -->
        </span>
      </div>
    </div>
    <div class="btn-grp mt-3">
      <button
        type="submit"
        class="cancel-button mb-3"
        (click)="cancelClicked()"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="save-button mb-3 ms-3"
        (click)="create()"
        [disabled]="validPayload()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        {{ dataInstance.isUpdating ? "Update" : "Create" }}
      </button>
    </div>
  </div>
</div>
