import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alertpopup',
  templateUrl: './alertpopup.component.html',
  styleUrls: ['./alertpopup.component.scss'],
})
export class AlertpopupComponent {
  checkBoxSelection: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AlertpopupComponent>
  ) {}

  saveFn() {
    this.dialogRef.close({ dontAskAgain: this.checkBoxSelection });
  }

  secondaryButton() {
    this.dialogRef.close({ dontAskAgain: this.checkBoxSelection });
  }

  closeFn() {
    this.dialogRef.close();
  }

  deleteFn() {
    this.dialogRef.close({ delete: true });
  }

  onCheckBoxChanged(event: any) {
    this.checkBoxSelection = event.target.checked;
  }
}
