import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/common/auth.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { ExploreHealthService } from 'src/app/services/explore-health/explore-health.service';
import { HealthAppService } from 'src/app/services/explore-health/health-app.service';
import { TenantService } from 'src/app/services/explore-health/tenant.service';
import { AvailableDataSets } from 'src/app/utils/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() hideHeaderItems: boolean = false;

  savedSearchdata: any = [];
  activeDownloads: any = [];
  isLoggingOut: boolean = false;
  savedSearchSubscription: Subscription | undefined;
  constructor(
    public auth: AuthService,
    public router: Router,
    private snack: SnackbarService,
    public haService: HealthAppService,
    public tenantService: TenantService,
    public exploreHealthService: ExploreHealthService
  ) {}

  ngOnInit() {
    this.getAppSettings();
    this.haService.userSettingsUpdated.subscribe((data) => {
      this.getAppSettings();
    });
  }
  getAppSettings() {
    this.haService.getDataSets();
    if (this.auth.hasLoggedIn) {
      this.getSettingsData();
    }
  }

  getSettingsData() {
    if (this.savedSearchSubscription) {
      this.savedSearchSubscription.unsubscribe();
    }
    this.savedSearchSubscription = this.haService
      .getUsersettings()
      .subscribe((data) => {
        this.savedSearchdata = data;
        this.haService.savedSearchData = data;
        this.auth.setDisplayOrder(
          this.savedSearchdata ? this.savedSearchdata.length + 1 : 1
        );
      });
  }

  toggleLogin() {
    if (this.auth.hasLoggedIn) {
      this.isLoggingOut = true;
      this.tenantService.logout();
    } else {
      this.auth.showLoginModal = true;
      this.haService.openModal();
      this.router.navigate(['/home']);
    }
  }

  goToSettings(tag: string) {
    switch (tag) {
      case 'settings':
        this.router.navigate(['settings/1']);
        return;
      case 'searches':
        this.router.navigate(['settings/2']);
        return;
      case 'support':
        this.router.navigate(['settings/3']);
        return;
      default:
        this.router.navigate(['settings/0']);
    }
  }

  isActiveDownloadsAvailable() {
    let downloads = this.auth.getGrantsNarrativeDownloads();
    if (downloads?.length != this.activeDownloads?.length) {
      this.activeDownloads = downloads ?? [];
      return downloads?.length > 0;
    }

    for (const obj of downloads) {
      if (
        !this.activeDownloads?.some((x: any) => x.grantOppId == obj.grantOppId)
      ) {
        this.activeDownloads = downloads ?? [];
        return downloads?.length > 0;
      }
    }

    return downloads?.length > 0;
  }

  checkNarrativeDownload(narrative: any) {
    if (
      !this.router.url.startsWith(
        `/grant-details/${narrative.grantOppId}/${narrative.stateCode}/${narrative.stateName}`
      )
    ) {
      window.open(
        `${document.baseURI}#/grant-details/${narrative.grantOppId}/${narrative.stateCode}/${narrative.stateName}`,
        '_blank'
      );

      // this.router.navigateByUrl(
      //   `grant-details/${narrative.grantOppId}/${narrative.stateCode}/${narrative.stateName}`
      // );
    } else {
      this.snack.openInfoBar('You are already in the current download page.');
    }
  }

  clearStore(dataSet: number) {
    if (
      this.router.url?.includes('/county-health') &&
      this.haService.healthDataSet == dataSet
    ) {
      return;
    }

    this.haService.clearInstance(dataSet);
    this.exploreHealthService.clearInstance();
    this.haService.clearExploreState(
      !this.router.url?.includes('/county-health') &&
        !this.router.url?.includes('/state-health')
    );

    if (
      !this.router.url?.includes('/county-health')
      // && !this.router.url?.includes('/state-health')
    ) {
      this.router.navigate(['county-health']);
    }
  }

  openCountyExplore(setting: any) {
    this.haService.healthDataSet = AvailableDataSets.PublicHealth;
    if (setting.stateSettings) {
      this.exploreHealthService.settings = setting.stateSettings;
      this.exploreHealthService.updateSavedSearch({ isCounty: false });
      this.router.navigate(['/county-health'], {
        queryParams: {
          state: setting.stateSettings.stateName,
          peerGroup: setting.stateSettings.peerGroup,
          populationAdjusted: setting.stateSettings.populationAdjusted,
          weightedScore: setting.stateSettings.weightedScore,
          isCountyBased: false,
          isDisparity: false,
          viewLevel: 2,
        },
      });
    } else if (setting.countySettings) {
      this.exploreHealthService.settings = setting.countySettings;
      this.exploreHealthService.updateSavedSearch({ isCounty: true });
      this.router.navigate(['/county-health'], {
        queryParams: {
          state: setting.countySettings.stateName,
          county: setting.countySettings.countyCode,
          peerGroup: setting.countySettings.peerGroup,
          weightedScore: setting.countySettings.weightedScore,
          isCountyBased: true,
          isDisparity: false,
          viewLevel: 3,
        },
      });
    } else if (setting.disparitySettings) {
      this.exploreHealthService.settings = setting.disparitySettings;
      if (
        setting.disparitySettings.countyCode != null &&
        setting.disparitySettings.countyName != null
      ) {
        this.exploreHealthService.updateSavedSearch({ isCounty: true });
        this.router.navigate(['/state-health'], {
          queryParams: {
            state: setting.disparitySettings.stateName,
            county: setting.disparitySettings.countyCode,
            view: setting.disparitySettings.view,
            isCountyBased: true,
            isDisparity: true,
            viewLevel: 3,
          },
        });
      } else {
        this.exploreHealthService.updateSavedSearch({ isCounty: false });
        this.router.navigate(['/state-health'], {
          queryParams: {
            state: setting.disparitySettings.stateName,
            //county: setting.disparitySettings.countyCode,
            view: setting.disparitySettings.view,
            isCountyBased: false,
            viewLevel: 2,
          },
        });
      }
    }
  }

  getTenantName() {
    return !this.hideHeaderItems && this.auth.hasLoggedIn
      ? this.auth.getIssuers()?.organizationName ?? ''
      : '';
  }

  isTenantNameAvailable() {
    return false;
    // return (
    //   !this.hideHeaderItems &&
    //   this.auth.hasLoggedIn &&
    //   this.auth.getIssuers() != null
    // );
  }

  goHome() {
    if (this.router.url?.startsWith('/login-callback')) {
      return;
    }
    this.router.navigate(['home']);
  }

  checkIfGrantDetailsPage() {
    return (
      this.router.url.startsWith('/grant-details') ||
      this.router.url.startsWith('/executive-dashboard') ||
      this.router.url.startsWith('/grants-dashboard')
    );
  }
}
