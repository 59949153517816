<div class="wrapper">
  <div class="px-5 py-3">
    <div class="headerText pb-3 mb-2 mt-2">
      <span>User Terms Agreement</span>
    </div>

    <div class="row">
      <div class="col-12" *ngIf="!pdfLoadFailed">
        <div *ngIf="isPdfLoading" class="hgt-400px">
          <mat-progress-spinner
            class="example-margin"
            [color]="'primary'"
            [mode]="'indeterminate'"
          >
          </mat-progress-spinner>
        </div>

        <div [ngClass]="{ 'd-none': isPdfLoading }">
          <ngx-extended-pdf-viewer
            [src]="
              'https://hadbdevstg.blob.core.windows.net/user-agreement-document/Termsandconditions.pdf?sp=r&st=2023-09-05T05:16:57Z&se=2026-09-05T13:16:57Z&spr=https&sv=2022-11-02&sr=b&sig=Ioz1X0bL1nwDz742ZcDkhK1bjWHm8hQ1a6LwfivAzRM%3D'
            "
            [zoom]="zoomLevel"
            [height]="'500px'"
            (pageChange)="onPageChange($event)"
            (pdfLoaded)="onEvent('pdfLoaded', $event)"
            (pdfLoadingFailed)="onEvent('pdfLoadingFailed', $event)"
            (progress)="onEvent('onProgress', $event)"
            [showToolbar]="true"
            [showSidebarButton]="true"
            [(sidebarVisible)]="sidebar"
            [showFindButton]="false"
            [showPagingButtons]="false"
            [showDrawEditor]="false"
            [showTextEditor]="false"
            [showZoomButtons]="false"
            [showPresentationModeButton]="false"
            [contextMenuAllowed]="false"
            [showOpenFileButton]="false"
            [showPrintButton]="false"
            [showDownloadButton]="false"
            [showSecondaryToolbarButton]="false"
            [showRotateButton]="false"
            [showHandToolButton]="false"
            [showScrollingButton]="false"
            [showSpreadButton]="false"
            [showPropertiesButton]="false"
          ></ngx-extended-pdf-viewer>
        </div>
        <div class="col-12" *ngIf="pdfLoadFailed">
          Failed to load the user agreement document
        </div>
      </div>
      <div class="row">
        <div class="col-12" *ngIf="isLastPage">
          <div
            class="check-box-container d-flex align-items-center justify-content-start"
          >
            <div
              class="d-flex align-items-center justify-content-start box-container"
            >
              <!-- <div class="check-box-container"> -->
              <label class="checkbox container-checkbox"
                ><input
                  type="checkbox"
                  id="label-hf"
                  [checked]="isSelected"
                  [(ngModel)]="isSelected" /><span
                  class="checkmark-checkbox"
                ></span
              ></label>
              <!-- </div> -->
              <span class="check-title"
                >I hereby accept the terms and conditions</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="btn-grp mt-3">
        <button
          type="submit"
          class="cancel-button mb-3"
          (click)="cancelClicked()"
        >
          <span
            *ngIf="loadingDecline"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Decline
        </button>
        <button
          type="submit"
          class="save-button mb-3 ms-3"
          [ngClass]="{ 'b-disabled': !isSelected || !isLastPage }"
          (click)="acceptAgreement()"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Accept
        </button>
      </div>
    </div>
  </div>
</div>
