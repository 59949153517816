import { Injectable, NgZone } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/components/loader/loader.service';

import { HealthAppService } from './health-app.service';

@Injectable({
  providedIn: 'root',
})
export class ExploreHealthResolver  {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    let statesCountiesMapping: any = {};
    return new Observable((observer) => {
      if (this.haService.stateList.length == 0) {
        this.loader.showLoader();
        this.haService.getStatesMasterData().subscribe({
          next: (response: any) => {
            statesCountiesMapping.states = response;
            this.haService.stateList = response;
            observer.next(statesCountiesMapping);
            observer.complete();
          },
          error: (error: any) => {
            this.loader.hideLoader();
            console.error(error);
          },
          complete: () => {
            this.loader.hideLoader();
            // console.log('Request completed');
          },
        });
      } else {
        statesCountiesMapping.states = this.haService.stateList;
        observer.next(statesCountiesMapping);
        observer.complete();
      }
    });
  }

  constructor(
    private haService: HealthAppService,
    private loader: LoaderService
  ) {}
}
