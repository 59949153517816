import { Injectable } from '@angular/core';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from 'src/app/utils/auth.config';
import { AuthService } from '../common/auth.service';
import { HealthAppService } from './health-app.service';
import { SnackbarService } from '../common/snackbar.service';
import {
  Constants,
  InsightCustomEvents,
  InsightEvents,
  InsightMessage,
} from 'src/app/utils/constants';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(
    private auth: AuthService,
    private oauthService: OAuthService,
    private haService: HealthAppService,
    private snackbar: SnackbarService,
    private router: Router
  ) {}

  public async logout(isUserAuthorized: boolean = true) {
    try {
      if (isUserAuthorized && this.auth.isAuthenticated()) {
        let metrixPayload = this.auth.getUserLoginMetrics();
        let userId = this.auth.getUserId();
        if (metrixPayload && userId) {
          this.haService.sendInsights(
            InsightEvents.User_Sessions,
            { ...metrixPayload, userId: userId },
            InsightCustomEvents.User_OUT
          );
        }
      }
    } catch (error) {}

    let issuer = this.getIssuers();
    if (issuer) {
      try {
        this.oauthService.configure(this.formAuthConfig(issuer, false));
        await this.oauthService.loadDiscoveryDocumentAndTryLogin();
        this.auth.clearUserData();
        this.haService.selectedState = null;
        this.haService.countySelected = null;
        this.oauthService.postLogoutRedirectUri = document.baseURI + `#/home`;
        this.oauthService.logOut(false);
      } catch (error) {
        this.hardLogout();
      }
    } else {
      this.hardLogout();
    }
  }

  hardLogout() {
    this.auth.clearUserData();
    this.haService.selectedState = null;
    this.haService.countySelected = null;
    this.router.navigate(['/home']);
  }

  initiateCodeFlow(
    tenant: any,
    continueLogin: boolean,
    hideLoginMsg: boolean = false
  ) {
    this.configureOIDC(
      this.formAuthConfig(tenant),
      continueLogin,
      hideLoginMsg
    );
  }

  initiateCodeFlowWithConfig(
    tenant: AuthConfig,
    continueLogin: boolean,
    hideLoginMsg: boolean = false
  ) {
    this.configureOIDC(tenant, continueLogin, hideLoginMsg);
  }

  async configureOIDC(
    authCodeFlowConfig: AuthConfig,
    continueLogin: boolean,
    hideLoginMsg: boolean = false
  ) {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then((success: boolean) => {})
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (!hideLoginMsg) {
          this.snackbar.openSuccessBar(
            'Successfully verified, redirecting you to login page. Please wait..'
          );
        }

        if (continueLogin) {
          this.oauthService.initCodeFlow();
        }

        this.oauthService.events.subscribe((e) => {
          // tslint:disable-next-line:no-console
          console.info('oauth/oidc event', e);

          if (e.type == 'silently_refreshed') {
            this.auth.tenantAccessToken = this.oauthService.getAccessToken();
            let tokenPayload = this.auth.isAuthenticated();
            if (
              tokenPayload &&
              tokenPayload.token &&
              tokenPayload.refreshToken
            ) {
              this.auth
                .refreshToken(tokenPayload.token, tokenPayload.refreshToken)
                .subscribe((user: any) => {
                  if (user) {
                    this.auth.hasAgreedTerns = user;
                    localStorage.setItem(
                      Constants.TOKEN_STORE,
                      JSON.stringify(user)
                    );
                  }
                });
            }
          }
        });
      });

    // // Display all events
    // if (!environment.production) {

    // }
  }

  formAuthConfig(item: any, saveIssuer: boolean = true): any {
    let config: AuthConfig = authCodeFlowConfig;
    if (item && item.issuerDetails) {
      config.issuer = `${item.issuerDetails.authority}`;
      config.tokenEndpoint = `${item.issuerDetails.tokenUrl}`;
      config.clientId = `${item.issuerDetails.clientId}`;
      config.scope = `offline_access ${item.issuerDetails.scope?.trim()}`;
      if (saveIssuer) {
        this.setIssuers(item);
      }
    }
    return config;
  }

  public setIssuers(issuerDetails: any) {
    localStorage.setItem(Constants.ISSUERS, JSON.stringify(issuerDetails));
  }

  public getIssuers(): any {
    const user = localStorage.getItem(Constants.ISSUERS);
    if (user) {
      let tokenPayload = JSON.parse(user);
      return tokenPayload; // will add interceptor to check the validity later.
    }
    return null;
  }

  refreshToken() {
    // this.oauthService
    //   .silentRefresh()
    //   .then(() => {
    //     console.log(this.oauthService.getAccessToken());
    //   })
    //   .catch((error: any) => {
    //     console.error(error);
    //   })
    //   .finally(() => {
    //     console.info(' refreshToken finally');
    //   });
  }
}
