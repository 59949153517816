import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { SavedSearchAddComponent } from './saved-search-add.component';
import { MaterialAppModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    component: SavedSearchAddComponent,
  },
];

@NgModule({
  declarations: [SavedSearchAddComponent],
  exports: [SavedSearchAddComponent],
  imports: [CommonModule, MaterialAppModule, FormsModule],
})
export class SavedSearchAddModule {}
