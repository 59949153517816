import { Component } from '@angular/core';
import {
  ActivatedRoute,
  ChildrenOutletContexts,
  Router,
} from '@angular/router';
import { slideInAnimation } from './slideInAnimation';
import { AuthService } from './services/common/auth.service';
import { HealthAppService } from './services/explore-health/health-app.service';
import { TenantService } from './services/explore-health/tenant.service';
import { SnackbarService } from './services/common/snackbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent {
  title = 'EY Impact';
  constructor(
    private contexts: ChildrenOutletContexts,
    private auth: AuthService,
    private tenantService: TenantService,
    private haService: HealthAppService,
    private activatedRoute: ActivatedRoute,
    private snack: SnackbarService,
    private router: Router
  ) {}

  onPageScroll(event: any) {
    if (this.router.url.includes('/county-health')) {
      this.haService.outcomeFilterScrollSubject.next(event);
    }

    if (this.router.url.includes('/state-health')) {
      this.haService.disparityFilterScrollSubject.next(event);
    }
  }

  ngOnInit() {
    if (this.auth.hasPropertyAgreedTerms() && !this.auth.hasAgreedTerns) {
      this.haService.openUserAgreementDialog();
    }

    this.configure();
    this.readRouteParams();
  }

  configure() {
    let issuer = this.tenantService.getIssuers();
    if (issuer != null) {
      this.tenantService.initiateCodeFlowWithConfig(
        this.tenantService.formAuthConfig(issuer, false),
        false,
        true
      );
    }
  }

  readRouteParams() {
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (!this.auth.isAuthenticated() && window.location.search.length > 0) {
        if (window.location.search.includes(`code=`)) {
          this.router.navigateByUrl('/login-callback' + window.location.search);
        } else if (
          window.location.search.includes('error=') &&
          window.location.search.includes('error_description=')
        ) {
          this.snack.openErrorBar(
            this.router.parseUrl(window.location.search)?.queryParams[
              'error_description'
            ] ?? 'Something went wrong! Please login agian..'
          );
        }
      }
    });
  }

  isLoginCallBack(): boolean {
    return this.router.url?.includes('/login-callback');
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }
}
