import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable()
export class Insights {
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      connectionString: environment.instrumentationKey,
      extensions: [this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          // errorServices: [new ErrorHandler()],
        },
      },
      enableDebug: !environment.production,
    },
  });

  constructor(private router: Router) {
    if (environment.production) {
      this.appInsights.loadAppInsights();
    }
  }

  // expose methods that can be used in components and services
  trackEvent(name: string, customProps?: any): void {
    if (environment.production) {
      let date: Date = new Date();
      this.appInsights.trackEvent(
        { name: name },
        {
          ...customProps,
          Date: formatDate(date, 'MM/dd/YYYY', 'en'),
          UTCDateTime: date.toISOString(),
        }
      );
    }
  }

  trackTrace(message: string): void {
    if (environment.production) {
      let date: Date = new Date();
      this.appInsights.trackTrace(
        { message: message },
        {
          Date: formatDate(date, 'MM/dd/YYYY', 'en'),
          UTCDateTime: date.toISOString(),
        }
      );
    }
  }

  trackPageView(message: any): void {
    if (environment.production) this.appInsights.trackPageView(message);
  }

  startTracker(message: any): void {
    if (environment.production) this.appInsights.startTrackEvent(message);
  }

  stopTracker(message: any, customProps?: any): void {
    if (environment.production)
      this.appInsights.stopTrackEvent(message, customProps);
  }
}
