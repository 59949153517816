import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { HealthAppService } from 'src/app/services/explore-health/health-app.service';
import { TenantService } from 'src/app/services/explore-health/tenant.service';

@Component({
  selector: 'app-user-agreement-dialog',
  templateUrl: './user-agreement-dialog.component.html',
  styleUrls: ['./user-agreement-dialog.component.scss'],
})
export class UserAgreementDialogComponent implements OnInit {
  loading = false;
  loadingDecline = false;
  isSelected = false;
  isLastPage = false;
  isPdfLoading = true;
  pdfLoadFailed = false;
  sidebar = false;
  pagesCount = 0;
  zoomLevel = 'page-width';

  constructor(
    public haService: HealthAppService,
    private snack: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserAgreementDialogComponent>,
    private tenant: TenantService
  ) {}

  ngOnInit(): void {
    this.isPdfLoading = true;
  }

  onPageChange(event: any): void {
    if (!this.isLastPage) this.isLastPage = event === this.pagesCount;
  }

  cancelClicked() {
    if (this.loadingDecline) {
      return;
    }
    this.loadingDecline = true;
    this.tenant.logout();
  }

  acceptAgreement() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.haService.acceptTerms().subscribe({
      next: (response: any) => {
        this.loading = false;
        this.dialogRef.close({
          result: true,
        });
      },
      error: (error) => {
        this.loading = false;
        this.snack.openErrorBar('Failed to accept terms agreement.');
      },
      complete: () => {},
    });
  }

  onEvent(event: string, $event: any) {
    if (event == 'onProgress') {
      this.isPdfLoading = $event.percent < 99.99;
    } else if (event == 'pdfLoadingFailed') {
      this.isPdfLoading = false;
      this.pdfLoadFailed = true;
    } else if (event == 'pdfLoaded') {
      this.pagesCount = $event?.pagesCount ?? 0;
    }
  }
}
