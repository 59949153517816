import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  BehaviorSubject,
  filter,
  first,
  map,
  switchMap,
  take,
} from 'rxjs';
import { LoaderService } from 'src/app/components/loader/loader.service';
import { Constants } from 'src/app/utils/constants';
import { AuthService } from './auth.service';
import { TenantService } from '../explore-health/tenant.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public auth: AuthService,
    private loader: LoaderService,
    private tenant: TenantService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return (
      req.url.includes(Constants.TENANT_API) ||
      req.url.includes(Constants.DATA_SET_API)
        ? next.handle(this.injectAppKey(req))
        : next.handle(
            this.auth.hasLoggedIn
              ? this.injectAppToken(req)
              : this.checkReq(req)
          )
    ).pipe(
      catchError((error: any) => {
        // Unauthorized
        // console.log(' status ', error);
        this.loader.hideLoader();
        if (error.status && error.status === 401) {
          let tokenPayload = this.auth.isAuthenticated();
          if (
            tokenPayload &&
            !error.url?.endsWith('/login') &&
            !error.url?.endsWith('/Refresh')
          ) {
            return this.handle401Error(req, next, tokenPayload, error.url);
          }
          this.redirectToLogin(error.url);
        }
        throw new HttpErrorResponse(error);
      })
    );
  }

  checkReq(req: HttpRequest<any>): HttpRequest<any> {
    if (
      req?.url?.includes(Constants.ACCESS_TOKE_VALIDATION_API) ||
      req?.url?.includes(Constants.USER_ORGANI_VALIDATION_API)
    ) {
      let tempToken = this.auth.getTempAccessToken();
      if (tempToken) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${tempToken}`,
          },
        });
      }
    }
    return req;
  }

  redirectToLogin(url: any) {
    this.tenant.logout();
  }

  injectAppToken(req: HttpRequest<any>) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
      },
    });
  }

  injectAppKey(req: HttpRequest<any>) {
    return req.clone({
      setHeaders: {
        appKey: environment._appKey,
      },
    });
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    tokenPayload: any,
    callbackUrl: any
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // this.tenant.refreshToken();

      return this.auth
        .refreshToken(tokenPayload.token, tokenPayload.refreshToken)
        .pipe(
          first(),
          map((user: any) => {
            this.auth.hasAgreedTerns = user;
            localStorage.setItem(Constants.TOKEN_STORE, JSON.stringify(user));
            return user;
          }),
          switchMap((data: any) => {
            // console.log(data);
            this.isRefreshing = false;
            this.refreshTokenSubject.next(data);
            return next.handle(this.injectAppToken(request));
          }),
          catchError((error: any) => {
            // console.log('Refresh Token failed', error);
            this.loader.hideLoader();
            this.loader.hideLoader();
            if (error instanceof HttpErrorResponse) {
              this.redirectToLogin(callbackUrl);
            }
            throw new Error(
              'failed to refresh token, fallback strategy applied, redirecting to login'
            );
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.injectAppToken(request));
        })
      );
    }
  }
}
