import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { ExploreHealthService } from 'src/app/services/explore-health/explore-health.service';
import { HealthAppService } from 'src/app/services/explore-health/health-app.service';
import * as topojson from 'topojson-client';
import { MatDialogRef } from '@angular/material/dialog';
import EDU from '../../../assets/data/counties.json';
import COUNTIES from '../../../assets/data/county-features.json';
import STATES from '../../../assets/data/states.json';

@Component({
  selector: 'app-d3-choropleth-map',
  templateUrl: './d3-choropleth-map.component.html',
  styleUrls: ['./d3-choropleth-map.component.scss'],
})
export class D3ChoroplethMapComponent implements OnInit {
  @ViewChild('tooltip') tooltipElement?: ElementRef;
  private tooltip: any;
  mergedArr: any;
  cdcArr: any;
  eyArr: any;

  constructor(
    private haService: HealthAppService,
    private exploreHealthService: ExploreHealthService,
    public dialogRef: MatDialogRef<D3ChoroplethMapComponent>
  ) {}
  @Input() selectedCounty: any;
  @Input() filterdata: any;
  filter = {
    cdc: true,
    ey: true,
  };
  data1: any;
  countyData: any;
  counties: any;

  ngOnInit(): void {
    // this.haService
    //   .getPeerCountyMapView(
    //     this.selectedCounty,
    //     this.exploreHealthService.countyFilterData.selectedDataSet
    //   )
    //   .subscribe((data) => {

    // });
    this.getMapView();
    // Load the GeoJSON data
  }

  async getMapView() {
    this.cdcArr = await this.haService.getMapView(this.selectedCounty, 'cdc');
    this.eyArr = await this.haService.getMapView(this.selectedCounty, 'ey');
    if (this.cdcArr && this.eyArr) {
      // this.counties = data;
      this.loadGeoData(this.cdcArr, this.eyArr);
    }
  }

  private loadGeoData(arr1: any, arr2: any): void {
    // d3.json('./assets/data/counties.json').then((edu: any) => {
    //   d3.json('./assets/data/county-features.json').then((county: any) => {
    let edu = EDU;
    let county = COUNTIES;

    let data1 = this.filterCounty(county, arr1, 'cdc');
    let data2 = this.filterCounty(county, arr2, 'ey');
    // console.log(data1);
    // console.log(data2);
    this.mergedArr = [];
    this.mergedArr = data1
      .concat(data2)
      .filter(
        (obj: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.id === obj.id)
      );
    // console.log(this.mergedArr);
    //county.objects.counties.geometries = data1
    this.createMap(edu, county);
    //   });
    // });
  }

  filterCounty(county: any, data: any, type: any) {
    this.countyData = county;
    // console.log(data);
    let data1 = data;
    let filteredData: any = [];
    data1.forEach((elem1: any) => {
      let data2 = this.countyData.objects.counties.geometries.filter(
        (elem: any) => {
          return elem.id == elem1.fipscd;
        }
      );
      if (data2.length > 0) {
        if (type == 'cdc') {
          data2[0][type] = this.filter.cdc;
          let item = this.cdcArr?.find(
            (it: any) => Number(it.fipscd) == data2[0].id
          );
          if (item) {
            data2[0]['countyName'] = item.countyName;
            data2[0]['stateAbbreviation'] = item.stateAbbreviation;
          }
        } else {
          data2[0][type] = this.filter.ey;
          let item = this.eyArr?.find(
            (it: any) => Number(it.fipscd) == data2[0].id
          );
          if (item) {
            data2[0]['countyName'] = item.countyName;
            data2[0]['stateAbbreviation'] = item.stateAbbreviation;
          }
        }
        filteredData.push(data2[0]);
      }
    });
    // console.log(filteredData);
    return filteredData;
  }

  private createMap(edu: any, county: any): void {
    const w = 992;
    const h = 650;
    const pad = 60;
    const svg = d3
      .select('#choropleth')
      .append('svg')
      .attr('width', w)
      .attr('height', h)
      .style('background-color', '#DDE8DA')
      .call(
        d3.zoom<SVGSVGElement, unknown>().on('zoom', function (e) {
          svg.attr('transform', e.transform);
        })
      ) // attach zoom handler to svg, but transform g element
      .append('g');

    // const tooltip = d3
    //   .select('#choropleth')
    //   .append('div')
    //   .attr('id', 'tooltip')
    //   .style('opacity', 0);

    this.tooltip = d3
      .select(this.tooltipElement?.nativeElement)
      .classed('d3-map-tooltip', true);

    const path: any = d3.geoPath();
    // const bachelorHigherHighLow = d3.extent(
    //   edu.map((d: any) => d.bachelorsOrHigher)
    // );
    // console.log(bachelorHigherHighLow);
    // const low: any = bachelorHigherHighLow[0];
    // const high: any = bachelorHigherHighLow[1];

    const topoData: any = topojson.feature(county, county.objects.counties);

    let states = STATES;

    const topoData1: any = topojson.feature(states, states.objects.states);

    // let features = d3.merge([topoData.features, topoData1.features]);

    // const colors = d3
    //   .scaleThreshold()
    //   .domain(d3.range(low, high, (high - low) / 9))
    //   .range([0, 10]);

    let zoomG = svg.append('g');
    var self = this;

    zoomG
      .selectAll('path')
      .data(topoData1.features)
      .enter()
      .append('path')
      .attr('class', 'state')
      .attr('d', path)
      .attr('stroke', '#021f4c')
      .attr('stroke-width', '1')
      // .attr('transform', 'translate(-6,-3)')
      .attr('fill', '#e9f5ff');

    zoomG
      .selectAll('path')
      .data(topoData.features)
      .enter()
      .append('path')
      .attr('class', 'county')
      .attr('d', path)
      .attr('stroke', '#289af87e')
      // .attr('fill', )
      .attr('fill', (d: any) => {
        let eduMatch = edu.filter((data: any) => data.fips == d.id);
        if (eduMatch[0]) {
          let ele = this.mergedArr.filter(
            (data: any) => data.id == eduMatch[0].fips
          );
          if (ele.length > 0 && ele[0].id) {
            return ele[0].cdc && this.filter.cdc && ele[0].ey && this.filter.ey
              ? '#ff0044'
              : ele[0].ey && this.filter.ey
              ? '#ffef5d'
              : ele[0].cdc && this.filter.cdc
              ? '#ff810a'
              : 'transparent';
          }
        }
        return 'transparent';
      })
      .attr('data-fips', (d: any) => d.id)
      .attr('data-education', (d: any) => {
        let eduMatch = edu.filter((data: any) => data.fips == d.id);
        if (eduMatch[0]) {
          return eduMatch[0].bachelorsOrHigher;
        }
        return 0;
      })
      .on('mouseover', function (this: any, e: any, d: any) {
        let item = self.mergedArr.find(
          (it: any) => Number(it.id) == Number(d.id)
        );
        if (item) {
          // console.log(e);
          d3.select(self.tooltipElement?.nativeElement)
            .style('visibility', 'visible')
            .style('opacity', 1)
            .style('left', e.layerX - 50 + 'px')
            .style('top', e.layerY - 45 + 'px');
          d3.select(self.tooltipElement?.nativeElement)
            .append('div')
            .text(item.countyName + ', ' + item.stateAbbreviation) //d.label
            .style('font-weight', 'bold')
            .style('font-size', '18px');
          // d3.select(self.tooltipElement?.nativeElement)
          //   .append('div')
          //   .text('County Code : ' + item.fipscd) //d.label .text(d.description)
          //   .style('margin-bottom', '5px')
          //   .style('margin-top', '5px')
          //   .style('font-size', '16px');
        }
      })
      .on('mouseout', (d) => {
        d3.select(self.tooltipElement?.nativeElement).style('opacity', 0);
        d3.select(self.tooltipElement?.nativeElement).style(
          'visibility',
          'hidden'
        );
        d3.select(self.tooltipElement?.nativeElement).selectChildren().remove();
      });

    try {
      const zoom: any = d3.zoom().scaleExtent([4, 8]);
      svg.transition()?.call(zoom, d3.zoomIdentity.scale(10));
    } catch (error) {}

    // set color scale
    // const xScale = d3
    //   .scaleLinear()
    //   .domain([low, high])
    //   .range([pad, w / 2 - pad]);

    // const xAxis = d3
    //   .axisBottom(xScale)
    //   .tickSize(25)
    //   .tickFormat((d: any) => Math.round(d) + '%')
    //   .tickValues(colors.domain());

    // svg
    //   .append('g')
    //   .attr('id', 'x-axis')
    //   .attr('transform', 'translate(' + w / 3 + ',' + (h - pad) + ')')
    //   .call(xAxis)
    //   .select('.domain')
    //   .remove();

    // set legend om color scale
    // svg
    //   .append('g')
    //   .attr('id', 'legend')
    //   .selectAll('rect')
    //   .data(colors.range().map((d) => d))
    //   .enter()
    //   .append('rect')
    //   .attr('height', 15)
    //   .attr('width', (d, i) => (w / 2 - pad * 2) / 9) // 9 taken from schemeGreens
    //   .attr('fill', (d) => d)
    //   .attr('x', (d, i) => pad + w / 3 + i * ((w / 2 - pad * 2) / 9))
    //   .attr('y', h - pad);
  }

  close() {
    this.dialogRef.close();
  }

  checkboxfn(event: any, type: any) {
    if (type == 'cdc') {
      this.filter.cdc = event.target.checked;
    } else {
      this.filter.ey = event.target.checked;
    }
    d3.select('#choropleth').selectAll('svg').remove();
    this.loadGeoData(this.cdcArr, this.eyArr);
  }
}
