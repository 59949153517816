import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/common/auth-guard.service';
import { ExploreHealthResolver } from './services/explore-health/explore-health.resolver';
import { LoginHomeGuardService } from './services/common/login-home-guard.service';
import { HomeGuardService } from './services/common/home-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [HomeGuardService],
    data: { animation: 'homePage' },
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutModule),
    data: { animation: 'aboutPage' },
  },
  {
    path: 'login-callback',
    loadChildren: () =>
      import('./pages/login-callback/login-callback.module').then(
        (m) => m.LoginCallbackModule
      ),
    canActivate: [LoginHomeGuardService],
  },
  {
    path: 'settings/:page-index',
    loadChildren: () =>
      import('./pages/settingspage/settingspage.module').then(
        (m) => m.SettingspageModule
      ),
    canActivate: [AuthGuardService],
    data: { animation: 'aboutPage' },
  },
  {
    path: 'grants-dashboard',
    loadChildren: () =>
      import('./pages/grants-dashboard-tab/grants-dashboard-tab.module').then(
        (m) => m.GrantsDashboardTabModule
      ),
    canActivate: [AuthGuardService],
    resolve: [ExploreHealthResolver],
    data: { animation: 'aboutPage' },
  },
  {
    path: 'executive-dashboard',
    loadChildren: () =>
      import(
        './pages/grants-executive-dashboard/grants-executive-dashboard.module'
      ).then((m) => m.GrantsExecutiveDashboardModule),
    canActivate: [AuthGuardService],
    resolve: [ExploreHealthResolver],
    // data: { animation: 'aboutPage' },
  },
  // {
  //   path: 'grant-details/:grant-details-id',
  //   loadChildren: () =>
  //     import('./pages/grant-details-page/grant-details-page.module').then(
  //       (m) => m.GrantDetailsPageModule
  //     ),
  //   canActivate: [AuthGuardService],
  //   data: { animation: 'aboutPage' },
  // },
  {
    path: 'grant-details/:grant-details-id/:state-code/:state-name',
    loadChildren: () =>
      import('./pages/grant-details-page/grant-details-page.module').then(
        (m) => m.GrantDetailsPageModule
      ),
    canActivate: [AuthGuardService],
    // data: { animation: 'aboutPage' },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'state-health',
    loadChildren: () =>
      import('./pages/state-health/state-health.module').then(
        (m) => m.StateHealthModule
      ),
    canActivate: [AuthGuardService],
    resolve: [ExploreHealthResolver],
    // data: { animation: 'statehealthPage' },
  },
  {
    path: 'county-health',
    loadChildren: () =>
      import('./pages/county-health/county-health.module').then(
        (m) => m.CountyHealthModule
      ),
    canActivate: [AuthGuardService],
    resolve: [ExploreHealthResolver],
    // data: { animation: 'countyhealthPage' },
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
