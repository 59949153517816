import { QuillModules } from 'ngx-quill';

export class Constants {
  public static TOKEN_STORE: string = 'token';
  public static USER_SETTING: string = 'user-setting';
  public static DISPLAY_ORDER: string = 'user-setting-display';
  public static TENANT_TOKEN_STORE: string = '9cas7-13nd8-das01';
  public static USER_METRICS: string = 'nxnUZxrjrk7ARqv0UZeCLc';
  public static NARRATIVE_DOWNLOAD: string = 'narrative-download-';
  public static NARRATIVE_DOWNLOAD_LIST: string = 'narrative-download-list';
  public static AGENCY_SUBSTANCE_ABUSE: string =
    'Substance Abuse and Mental Health Services Adminis';
  public static STRATEGY_NAVIGATION_CONFIRMATION: string =
    'navigation-for-strategy';
  public static NOFO_NAVIGATION_CONFIRMATION: string = 'navigation-for-nofo';
  public static GRANTS_NAVIGATION_CONFIRMATION: string =
    'navigation-for-grants';
  public static DEFAULT_UID: string = 'ABCabc123';
  public static ISSUERS: string = 'issuer-user';
  public static ACCESS_TOKEN_CONFIG: string = 'code';
  public static TEMP_ACCESS_TOKEN: string = '7264bc9vx6e921x91623-23x';
  public static AGENCY_UPLOADED: string = 'AgencyUploaded';
  public static TENANT_API: string = 'tenant';
  public static DATA_SET_API: string = 'common/datasets';
  public static ACCESS_TOKE_VALIDATION_API: string = 'accounts/AccessToken/';
  public static USER_ORGANI_VALIDATION_API: string = 'accounts/user/org';
  public static CSP_NOUNCE: string =
    '01f95194feb47f63bb88c4bb3df1355d462b82867862bdabd8e1d02493a479a0';
  // public static CSP_NOUNCE: string =
  //   '83474ce2c4867816a7962bb7e78889a6e58516ca06ad1fc933c3ae89df2d8b3a';

  // public static ATTACHMENTALLFILETYPES =
  //   '.gif,.ico,.jpg,.jpeg,.png,.docx,.doc,.pdf,.dgn,.dwg,.dxf,.odt,.stl,.xml,.psd,.tif,.ppt,.pptx,.xls,.xlsx,.xlsm,.txt,.mp4,.m4v,.m4p,.ogg,.ogv,.avi,.wmv,.mov,.qt,.webm,.mpg,.mp2,.mpeg,.mpe,.mpv,.flv,.swf,.avchd';
  public static ATTACHMENTALLFILETYPES = '.jpg,.jpeg,.png,.docx,.doc,.pdf';
  public static GRANT_DOCUEMNTS = '.docx,.doc,.pdf';
  public static GRANT_OTHER_DOCUEMNTS = '.docx,.doc,.pdf,.xls,.xlsx,.xlsm';

  public static QUILL_CONFIG: QuillModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike', 'link'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ['clean'],
      ],
    },
  };

  public static HEALTH_FACTORS_MASTER = [
    { code: 'AO', name: 'Adult Obesity' },
    { code: 'TB', name: 'Teen Births' },
    { code: 'BDI', name: 'Brain Development issues' },
    { code: 'PI', name: 'Physical Inactivity' },
    { code: 'DWV', name: 'Drinking Water Violations' },
    { code: 'MHP', name: 'Mental Health Providers' },
    { code: 'PAQ', name: 'Poor Air Quality' },
    { code: 'PN', name: 'Poor Nutrition' },
    { code: 'TU', name: 'Tobacco Use' },
    { code: 'EAC', name: 'Excessive Alcohol Consumption' },
    { code: 'SE', name: 'Social Environments' },
    { code: 'PE', name: 'Physical Environments' },
    { code: 'GEN', name: 'Genetic' },
  ];

  public static HEALTH_OUTCOMES_MASTER = [
    { code: 'AO', name: 'Adult Obesity' },
    { code: 'TB', name: 'Teen Births' },
    { code: 'BDI', name: 'Brain Development issues' },
    { code: 'PI', name: 'Physical Inactivity' },
    { code: 'DWV', name: 'Drinking Water Violations' },
    { code: 'MHP', name: 'Mental Health Providers' },
    { code: 'PAQ', name: 'Poor Air Quality' },
    { code: 'PN', name: 'Poor Nutrition' },
    { code: 'TU', name: 'Tobacco Use' },
    { code: 'EAC', name: 'Excessive Alcohol Consumption' },
    { code: 'SE', name: 'Social Environments' },
    { code: 'PE', name: 'Physical Environments' },
    { code: 'GEN', name: 'Genetic' },
  ];

  public static COUNTY_SUMMARY = [
    {
      category: 'Health Behaviors',
      healthFactor: 'Adult smoking',
      weight: 100,
      bucksCounty: 'county',
      peerAverage: 50,
      value: 30.231,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Adult Obesity',
      weight: 80,
      bucksCounty: 'county',
      peerAverage: 60,
      value: 30.214,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Food environment index',
      weight: 70,
      bucksCounty: 'county',
      peerAverage: 40,
      value: 10.102,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Physical inactivity',
      weight: 90,
      bucksCounty: 'county',
      peerAverage: 50,
      value: 50.412,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Access to exercise opportunities',
      weight: 100,
      bucksCounty: 'county',
      peerAverage: 50,
      value: 25.003,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Excessive drinking',
      weight: 120,
      bucksCounty: 'county',
      peerAverage: 70,
      value: 40.101,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'alcohol-impaired driving deaths',
      weight: 100,
      bucksCounty: 'county',
      peerAverage: 50,
      value: 30.241,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Sexually transmitted infections',
      weight: 100,
      bucksCounty: 'county',
      peerAverage: 50,
      value: 12.341,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Teen births',
      weight: 100,
      bucksCounty: 'county',
      peerAverage: 50,
      value: 16.412,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Food environment index',
      weight: 100,
      bucksCounty: 'county',
      peerAverage: 50,
      value: 24.501,
    },
    {
      category: 'Health Behaviors',
      healthFactor: 'Food environment index',
      weight: 100,
      bucksCounty: 'county',
      peerAverage: 50,
      value: 1.51,
    },
  ];

  public static SECTION_OPTIONS = [
    {
      position: 0,
      title: 'Instructions',
      sectionId: -4,
      isCustomSection: false,
      showMainTitle: true,
      isSubOption: false,
      isLastSubOption: false,
      sectionOptions: [],
    },
    {
      position: 1,
      title: 'Select sections',
      sectionId: -3,
      isCustomSection: false,
      showMainTitle: true,
      isSubOption: false,
      isLastSubOption: false,
      sectionOptions: [],
    },
    {
      position: 2,
      title: 'Select flow',
      sectionId: -2,
      isCustomSection: false,
      showMainTitle: true,
      isSubOption: false,
      isLastSubOption: false,
      sectionOptions: [],
    },
    {
      position: 3,
      title: 'Select settings',
      sectionId: -1,
      isCustomSection: false,
      showMainTitle: true,
      isSubOption: false,
      isLastSubOption: false,
      sectionOptions: [],
    },
    // {
    //   position: 3,
    //   title: 'Select factors',
    //   isCompleted: false,
    // },
    // {
    //   position: 4,
    //   title: 'Select counties',
    //   isCompleted: false,
    // },
    // {
    //   position: 5,
    //   title: 'Select demographics',
    //   isCompleted: false,
    // },
    // {
    //   position: 6,
    //   title: 'Additional Data',
    //   isCompleted: false,
    // },
    // {
    //   position: 7,
    //   title: 'Select strategies',
    //   isCompleted: false,
    // },
    // {
    //   position: 8,
    //   title: 'Customize sections',
    //   isCompleted: false,
    // },
  ];
}

export enum DRAFT_SECTIONS {
  INSTRUCTIONS = 0,
  INTRODUCTION,
  INTRODUCTION_FLOW,
  SELECT_COMPARISON,
  SELECT_FACTORS,
  SELECT_DEMOGRAPHICS,
  SELECT_SVI,
  SELECT_STRATEGIES,
  SELECT_CUSTOM_SECTIONS,
  IMPLEMENTATION_APPROACH,
  CATCHMENT_AREA,
  OTHER_DEMOGRAPHIC_INFO,
  SELECT_NETWORK_PROVIDERS,
  GENERATE_GRANT,
}

export enum GRANT_FLOW_TYPE {
  FLOW_NOT_STARTED = 1,
  SITE_GENERATED,
  CUSTOM_GENERATED,
}

export enum DRAFT_STAGE {
  DRAFTING = 1,
  ADDING_STRATEGIES,
  ADDING_FACTORS,
  ADDING_SVI_FACTORS,
}

export enum SECTION_TYPES {
  HEALTH_OUTCOMES = 1,
  STRATEGIES_DESELECT_ALL,
  PRIMARY_FACTOR_SELECT_FIRST_ITEM,
  FIND_PRIMARY_ITEM,
  COUNTIES_DESELECT_ALL,
  COUNTIES_SELECT_ALL,
  DEMOGRAPHICS_DESELECT_ALL,
  SET_PRIMARY_FACTOR_SELECTION,
  CLEAR_FACTOR_SUMMARY,
  HEALTH_SVI,
  OTHER_DEMO_DESELECT_ALL,
  PROVIDERS_DESELECT_ALL,
}

export enum SECTION_KEYWORDS {
  POPULATION_OF_FOCUS = 'PopulationOfFocus',
  IMPLEMNENTATION_APPROACH = 'ImplementationApproach',
  EVIDENCE_BASED_PRACTICES = 'EvidenceBasedPractises',
  ORGANIZATIONAL_EXPERIENCE = 'OrganizationalExperience',
  DATA_COLLECTION = 'DataCollection',
}

export enum InsightCustomEvents {
  User_IN = 'UserIn',
  User_OUT = 'UserOut',
  User_Session = 'UserSession',
  Unauthorized = 'Unauthorized',
  OtheEvent = 'OtherEvent',
}

export enum InsightEvents {
  User_Sessions = 'UserSessions',
  User_LoggedIn = 'UserLoggedIn',
  User_Unauthorized = 'UserUnauthorized',
}

export enum GrantResponseType {
  Custom_Flow = 'Custom',
  SiteGenerated_Flow = 'SiteGenerated',
}

export enum AvailableDataSets {
  PublicHealth = 1,
  MentalHealth = 2,
}

export enum STRATEGY_DIALOG_STATE {
  CREATE_STAGE = 1,
  VIEW_STAGE,
  EDIT_STAGE,
}

export enum GRANT_UI_STATE {
  ERROR = 1,
  LOADING,
  FILE_UPLOAD,
  REVIEW_OUTLINE,
  UPLOAD_PREVIOUS_RESPONSES,
  REVIEW_NARRATIVE_INFORMATION,
  ADDITIONAL_FILE_UPLOAD,
  CHAT_UI,
}

export enum VIEW_LEVEL {
  NATIONAL = 1,
  STATE,
  COUNTY,
}

export class InsightMessage {
  public static UserLoggin: any = {
    message: 'User Login Success',
  };
  public static UserLogout: any = {
    message: 'User Logout Success',
  };
}
